import * as axiosUtils from "../utils/axiosUtils";
import * as api from "../constants/api";
// import axios from "../services/axios";

export const actionTypeSkiftlogger = "SKIFTLOGGER";

export const getSkiftlogger = (skiftloggerFormState) => {
  const {
    selectedTjenestenummer,
    fraDatoValidationErrorText,
    tilDatoValidationErrorText,
    ...params
  } = skiftloggerFormState;

  return axiosUtils.GetAllWithParamsSerializer(
    api.GET_SKIFTLOGGER_URL,
    params,
    actionTypeSkiftlogger,
    "skiftlogger"
  );
};

export const actionTypeVentilstilling = "VENTILSTILLING";

export const getVentilstilling = (id) =>
  axiosUtils.GetAll(api.GET_VENTILSTILLING_URL(id), actionTypeVentilstilling);

export const getSkiftRapport = (skiftloggerFormState) => {
  return axiosUtils.GetAllWithParams(
    api.GET_SKIFTLOGGER_RAPPORT_URL,
    skiftloggerFormState
  );
};
