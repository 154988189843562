import React from "react";
import { Link } from "react-router-dom";

import Table from "../../Common/SortableTableContainer";
import { HeaderCell } from "../../Common/Tables/index";
import { formatDateTimeHavingNorwegianDateTime } from "../../../utils/datetimeUtils";

import sitePaths from "../../../constants/sitePaths";

const renderRow = (uregistrertekontroll) => {
  if (uregistrertekontroll) {
    return (
      <tr
        key={`ileggelserTableBodyTr${uregistrertekontroll.id}`}
        style={
          uregistrertekontroll.deaktivertDato
            ? { backgroundColor: "#d32316", color: "white" }
            : {}
        }
      >
        <td>
          {formatDateTimeHavingNorwegianDateTime(
            uregistrertekontroll.tidspunkt
          )}
        </td>
        <td>{uregistrertekontroll.status}</td>
        <td>
          <Link
            to={`${sitePaths.uregistrerte.kontroller.detalj.shortUrl.getUrl(
              uregistrertekontroll.id
            )}`}
            className="underline"
          >
            <span>{uregistrertekontroll.nummer}</span>
          </Link>
        </td>
        <td>{uregistrertekontroll.adresse}</td>
        <td>{uregistrertekontroll.fabrikkMerke}</td>
        <td>{uregistrertekontroll.kjoretoyType}</td>
        <td>{uregistrertekontroll.farge}</td>
        <td>{uregistrertekontroll.betjentnummer}</td>
      </tr>
    );
  }
};

const UregistrerteKontrollerList = ({ uregistrertekontroller }) => {
  return (
    <div style={{ marginTop: 20, paddingRight: 50, paddingLeft: 50 }}>
      <div className="ileggelse-list-page-content-container">
        <Table
          id="ileggelse-liste"
          storeKey="ileggelser"
          items={uregistrertekontroller}
          renderRow={renderRow}
        >
          <HeaderCell>Dato</HeaderCell>
          <HeaderCell>Status</HeaderCell>
          <HeaderCell>Id</HeaderCell>
          <HeaderCell>Adresse</HeaderCell>
          <HeaderCell>Fabrikkmerke</HeaderCell>
          <HeaderCell>Kjøretøystype</HeaderCell>
          <HeaderCell>Farge</HeaderCell>
          <HeaderCell>Tjenestenummer</HeaderCell>
        </Table>
      </div>
    </div>
  );
};

export default UregistrerteKontrollerList;
