import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";

import KopiContainer from "../Common/Miljogebyr/Detalj/KopiContainer";
import DetailPageHeader from "../Common/Detalj/DetailPageHeader";
import MiljogebyrGridOgKommentarContainer from "../Common/Miljogebyr/Detalj/MiljogebyrGridOgKommentarContainer";
import TilbakemeldingerFraBybetjenterContainer from "../Common/Miljogebyr/Detalj/TilbakemeldingerFraBybetjenterContainer";
import MottakerAvMiljogebyrContainer from "../Common/Miljogebyr/Detalj/MottakerAvMiljogebyrContainer";
import MiljokontrollContainer from "../Common/Miljogebyr/Detalj/MiljokontrollContainer";
import MiljokontrollKommentarerContainer from "../Common/Miljogebyr/Detalj/MiljokontrollKommentarerContainer";
import BilderContainer from "../Common/Miljogebyr/Detalj/BilderContainer";
import BilderAndMiljokontrollsobservasjonsbilderContainer from "../Common/Miljogebyr/Detalj/BilderAndMiljokontrollsobservasjonsbilderContainer";
import Ettersendelser from "../Common/Miljogebyr/Detalj/Ettersendelser";
import NyeEttersendelser from "../Common/Miljogebyr/Detalj/NyeEttersendelser";
import KlageinformasjonContainer from "../Common/Miljogebyr/Detalj/KlageinformasjonContainer";
import Map from "../Common/Maps/MapView";
import Spinner from "../Common/Spinner";

import { getMiljogebyr as getMiljogebyrAction } from "../../actions/miljogebyrerActions";
import { getMiljogebyrEttersendelser as getMiljogebyrEttersendelserAction } from "../../actions/miljogebyrEttersendelserActions";
import { getBybetjenter as getBybetjenterAction } from "../../actions/bybetjenterActions";

import * as title from "../../constants/titles";

class InnsynMiljogebyrDetaljPage extends Component {
  static propTypes = {
    computedMatch: PropTypes.object.isRequired,
    miljogebyr: PropTypes.object.isRequired,
    getMiljogebyr: PropTypes.func.isRequired,
    getMiljogebyrEttersendelser: PropTypes.func.isRequired,
    getBybetjenter: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoadingMiljokontrollBilder: false,
    };
  }

  componentDidMount() {
    let {
      miljogebyr,
      getMiljogebyr,
      getMiljogebyrEttersendelser,
      getBybetjenter,
    } = this.props;
    window.scrollTo(0, 0);
    document.title = miljogebyr.ileggelsesnummer
      ? title.innsynMiljogebyrDetalj(miljogebyr.ileggelsesnummer)
      : document.title;
    getMiljogebyr(this.props.computedMatch.params.id);
    getMiljogebyrEttersendelser(this.props.computedMatch.params.id);
    getBybetjenter(this.props.computedMatch.params.id);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.miljogebyr &&
      this.props.miljogebyr &&
      prevProps.miljogebyr.ileggelsesnummer !==
        this.props.miljogebyr.ileggelsesnummer
    ) {
      document.title = title.innsynMiljogebyrDetalj(
        this.props.miljogebyr.ileggelsesnummer
      );
    }
  }

  toggleIsLoadingMiljokontrollBilder = () => {
    this.setState((state) => ({
      isLoadingMiljokontrollBilder: !state.isLoadingMiljokontrollBilder,
    }));
  };

  render() {
    const { isLoadingMiljokontrollBilder } = this.state;

    if (
      this.props.miljogebyr &&
      this.props.miljogebyr.id === this.props.computedMatch.params.id
    ) {
      return (
        <div className="ileggelse-content-wrapper">
          <DetailPageHeader
            pageName={"Miljøgebyr"}
            pageId={String(this.props.miljogebyr.ileggelsesnummer)}
            isMakulert={this.props.miljogebyr.isMakulert}
          />
          <Tabs
            defaultActiveKey={1}
            id="InnsynnIleggelseTabs"
            className="detailjTab"
            onSelect={() => {
              this.forceUpdate(); // For tab 2 to update.
            }}
          >
            <Tab eventKey={1} title="Miljogebyr">
              <div className="ileggelse-tab-content-wrapper">
                <div style={{ marginBottom: 30, marginLeft: 50 }}>
                  <KopiContainer />
                </div>
                <MiljogebyrGridOgKommentarContainer
                  miljogebyr={this.props.miljogebyr}
                />
                <TilbakemeldingerFraBybetjenterContainer
                  miljogebyrId={this.props.miljogebyr.id}
                />
                <MottakerAvMiljogebyrContainer
                  miljogebyrId={this.props.miljogebyr.id}
                />
                {this.props.miljogebyr &&
                  !this.props.miljogebyr.miljoKontrollId && (
                    <BilderContainer
                      miljogebyrId={this.props.computedMatch.params.id}
                    />
                  )}
                {this.props.miljogebyr &&
                  this.props.miljogebyr.miljoKontrollId && (
                    <>
                      <MiljokontrollContainer
                        miljoKontrollId={this.props.miljogebyr.miljoKontrollId}
                        toggleIsLoadingMiljokontrollBilder={
                          this.toggleIsLoadingMiljokontrollBilder
                        }
                      />
                      <MiljokontrollKommentarerContainer
                        miljogebyrId={this.props.computedMatch.params.id}
                      />
                      <BilderAndMiljokontrollsobservasjonsbilderContainer
                        miljogebyrId={this.props.computedMatch.params.id}
                        isLoadingMiljokontrollBilder={
                          isLoadingMiljokontrollBilder
                        }
                      />
                    </>
                  )}
                <Ettersendelser />
                <NyeEttersendelser />
                <KlageinformasjonContainer
                  miljogebyrId={this.props.miljogebyr.id}
                />
              </div>
            </Tab>
            <Tab eventKey={2} title="Kart">
              <Map
                lat={this.props.miljogebyr.latitude}
                lng={this.props.miljogebyr.longitude}
              />
            </Tab>
          </Tabs>
        </div>
      );
    } else {
      return (
        <div>
          <Spinner isLoading />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyr: state.miljogebyr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyr: (miljogebyrId) =>
      dispatch(getMiljogebyrAction(miljogebyrId)),
    getMiljogebyrEttersendelser: (miljogebyrId) =>
      dispatch(getMiljogebyrEttersendelserAction(miljogebyrId)),
    getBybetjenter: () => dispatch(getBybetjenterAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnsynMiljogebyrDetaljPage);
