import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Dropdown } from "react-bootstrap";

import classnames from "classnames";

import "./DropDown.css";

const GetValues = (data, title) => {
  let arr = [];
  if (title) {
    arr.push({ id: undefined, navn: title });
  }

  if (!Array.isArray(data)) {
    return arr;
  }

  return [...arr, ...data];
};

const getSelectedTitle = (id, items, initTitle) => {
  const selectedTitleObject = items.find((item) => item.id === id);
  if (selectedTitleObject) {
    return selectedTitleObject.navn;
  }
  return initTitle;
};

const DropDown = ({
  label,
  title,
  name,
  id,
  items,
  onChange,
  validationError,
  selected,
  disabled,
}) => {
  const [selectedTitle, setSelectedTitle] = useState(
    getSelectedTitle(selected, items, title)
  );

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  useEffect(() => {
    setSelectedTitle(getSelectedTitle(selected, items, title));
  }, [selected, items, title]);

  const handleChange = (id) => {
    const e = { target: { name: name, value: id } };
    onChange(e);
  };

  const getSelectedDropdownTitleClassName = () => {
    if (dropdownIsOpen && title === selectedTitle) {
      return "selected-dropdown-title selected-dropdown-title-placeholder-open";
    } else if (!dropdownIsOpen && title === selectedTitle) {
      return "selected-dropdown-title selected-dropdown-title-placeholder";
    }
    return "selected-dropdown-title";
  };
  
  return (
    <div className={classnames("form-group", { "has-error": validationError })}>
      {label && (
        <label className="control-label" htmlFor={id}>
          {label}
        </label>
      )}
      <Dropdown
        id={id}
        title={name}
        className="select-dropdown"
        onToggle={(open) => setDropdownIsOpen(open)}
        disabled={disabled}
      >
        <Dropdown.Toggle
          className={dropdownIsOpen ? "select-dropdown-toggle-is-open" : ""}
        >
          <div className={getSelectedDropdownTitleClassName()}>
            {selectedTitle || title}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="select-dropdown-menu">
          {GetValues(items, selected ? title : undefined).map((item) => {
            return (
              <Dropdown.Item
                key={item.id}
                onClick={() => handleChange(item.id)}
              >
                {item.navn}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>

      {validationError && <span className="help-block">{validationError}</span>}
    </div>
  );
};

DropDown.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  validationError: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default DropDown;
