import React, { Component } from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserNavItem from "./UserNavItem";

import { authorized } from "../../utils/authorizationUtils";
import { getRedirectPath } from "../../utils/pathnameUtils";

import sitePaths from "../../constants/sitePaths";
import { getHostFromEnv } from "../../constants/api/config";

import {
  bypatruljen,
  bypatruljenArray,
  skiftlogg,
  juridisk,
  juridiskArray,
  innsyn,
  stedfortrederDriftsleder,
  bypatruljenAndJuridiskArray,
} from "../../constants/roles";

import byvaapen from "../../images/Oslo-logo-sort-RGB.svg";
import byvaapen2x from "../../images/Oslo-logo-sort-RGB.svg";
import byvappen3x from "../../images/Oslo-logo-sort-RGB.svg";
import "./NavigationBar.css";

class NavigationBar extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    location: PropTypes.object,
  };

  // The following functions are needed in order to highligt the main tab due to sub tab change.
  // Why not to just use a Redirect with react-router-dom? Because of intercepted route popup will appear two times...
  getIleggelseNavigationPath = () => {
    if (
      window.location.pathname === sitePaths.ileggelser.ettersendelser.shortUrl
    ) {
      return sitePaths.ileggelser.ettersendelser.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.ileggelser.ettersendelserTilGodkjenning.shortUrl
    ) {
      return sitePaths.ileggelser.ettersendelserTilGodkjenning.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.ileggelser.elsparksykkelGebyr.shortUrl
    ) {
      return sitePaths.ileggelser.elsparksykkelGebyr.shortUrl;
    }
    return sitePaths.ileggelser.oversikt.shortUrl;
  };

  getMiljogebyrNavigationPath = () => {
    if (
      window.location.pathname === sitePaths.miljogebyrer.tilBehandling.shortUrl
    ) {
      return sitePaths.miljogebyrer.tilBehandling.shortUrl;
    } else if (
      window.location.pathname ===
      sitePaths.miljogebyrer.miljokontrolloversikt.shortUrl
    ) {
      return sitePaths.miljogebyrer.miljokontrolloversikt.shortUrl;
    }
    return sitePaths.miljogebyrer.oversikt.shortUrl;
  };

  getUregistrerteNavigationPath = () => {
    return sitePaths.uregistrerte.kontroller.shortUrl;
  };

  getGrunndataNavigationPath = () => {
    if (window.location.pathname === sitePaths.grunndata.aktorer) {
      return sitePaths.grunndata.aktorer;
    }
    if (window.location.pathname === sitePaths.grunndata.bypatruljen) {
      return sitePaths.grunndata.bypatruljen;
    }
    if (window.location.pathname === sitePaths.grunndata.farger) {
      return sitePaths.grunndata.farger;
    }
    if (window.location.pathname === sitePaths.grunndata.kjoretoyMerker) {
      return sitePaths.grunndata.kjoretoyMerker;
    }
    if (window.location.pathname === sitePaths.grunndata.kjoretoyTyper) {
      return sitePaths.grunndata.kjoretoyTyper;
    }
    if (
      window.location.pathname ===
      sitePaths.grunndata.overtredelseskoderIleggelser
    ) {
      return sitePaths.grunndata.overtredelseskoderIleggelser;
    }
    if (
      window.location.pathname ===
      sitePaths.grunndata.overtredelseskoderMiljogebyr
    ) {
      return sitePaths.grunndata.overtredelseskoderMiljogebyr;
    }
    if (
      window.location.pathname === sitePaths.grunndata.internkoderIleggelser
    ) {
      return sitePaths.grunndata.internkoderIleggelser;
    }
    if (
      window.location.pathname === sitePaths.grunndata.internkoderMiljogebyr
    ) {
      return sitePaths.grunndata.internkoderMiljogebyr;
    }
    if (window.location.pathname === sitePaths.grunndata.nasjoner) {
      return sitePaths.grunndata.nasjoner;
    }
    if (window.location.pathname === sitePaths.grunndata.rettekoder) {
      return sitePaths.grunndata.rettekoder;
    }
    if (window.location.pathname === sitePaths.grunndata.vedtakskoder) {
      return sitePaths.grunndata.vedtakskoder;
    }
    return sitePaths.grunndata.shortUrl;
  };

  getRapporterNavigationPath = () => {
    if (window.location.pathname === sitePaths.rapporter.skiftlogg.oversikt) {
      return sitePaths.rapporter.skiftlogg.oversikt;
    }
    if (
      window.location.pathname ===
      sitePaths.rapporter.piggdekkontroller.oversikt
    ) {
      return sitePaths.rapporter.piggdekkontroller.oversikt;
    }
    if (window.location.pathname === sitePaths.rapporter.klager.oversikt) {
      return sitePaths.rapporter.klager.oversikt;
    }
    if (
      window.location.pathname ===
      sitePaths.rapporter.juridiskSaksbehandler.oversikt
    ) {
      return sitePaths.rapporter.juridiskSaksbehandler.oversikt;
    }
    if (
      window.location.pathname ===
      sitePaths.rapporter.uferdigstilteKlagesaker.oversikt
    ) {
      return sitePaths.rapporter.uferdigstilteKlagesaker.oversikt;
    }
    if (
      window.location.pathname ===
      sitePaths.rapporter.antallIleggelserPerIleggelsetype.oversikt
    ) {
      return sitePaths.rapporter.antallIleggelserPerIleggelsetype.oversikt;
    }
    if (
      window.location.pathname === sitePaths.rapporter.makuleringer.oversikt
    ) {
      return sitePaths.rapporter.makuleringer.oversikt;
    }
    if (window.location.pathname === sitePaths.rapporter.aarsrapport.oversikt) {
      return sitePaths.rapporter.aarsrapport.oversikt;
    }
    return getRedirectPath(sitePaths.rapporter.shortUrl);
  };

  getInnkrevingNavigationPath = () => {
    if (
      window.location.pathname ===
      sitePaths.innkreving.miljogebyr.venter.shortUrl
    ) {
      return sitePaths.innkreving.miljogebyr.venter.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.innkreving.miljogebyr.stoppet.shortUrl
    ) {
      return sitePaths.innkreving.miljogebyr.stoppet.shortUrl;
    }
    if (
      window.location.pathname === sitePaths.innkreving.miljogebyr.klar.shortUrl
    ) {
      return sitePaths.innkreving.miljogebyr.klar.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.innkreving.miljogebyr.behandlesInnkreving.shortUrl
    ) {
      return sitePaths.innkreving.miljogebyr.behandlesInnkreving.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.innkreving.miljogebyr.feilet.shortUrl
    ) {
      return sitePaths.innkreving.miljogebyr.feilet.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.innkreving.miljogebyr.vellykket.shortUrl
    ) {
      return sitePaths.innkreving.miljogebyr.vellykket.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.innkreving.ileggelser.venter.shortUrl
    ) {
      return sitePaths.innkreving.ileggelser.venter.shortUrl;
    }
    if (
      window.location.pathname === sitePaths.innkreving.ileggelser.klar.shortUrl
    ) {
      return sitePaths.innkreving.ileggelser.klar.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.innkreving.ileggelser.behandlesInnkreving.shortUrl
    ) {
      return sitePaths.innkreving.ileggelser.behandlesInnkreving.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.innkreving.ileggelser.feilet.shortUrl
    ) {
      return sitePaths.innkreving.ileggelser.feilet.shortUrl;
    }
    if (
      window.location.pathname ===
      sitePaths.innkreving.ileggelser.vellykket.shortUrl
    ) {
      return sitePaths.innkreving.ileggelser.vellykket.shortUrl;
    }
    return sitePaths.innkreving.ileggelser.default;
  };

  getInnsynNavigationPath = () => {
    if (window.location.pathname === sitePaths.innsyn.miljogebyrer.oversikt) {
      return sitePaths.innsyn.miljogebyrer.oversikt;
    }
    if (
      authorized([
        ...bypatruljenArray,
        ...juridiskArray,
        stedfortrederDriftsleder,
      ]) &&
      window.location.pathname === sitePaths.innsyn.ileggelser.oversikt
    ) {
      return sitePaths.innsyn.ileggelser.oversikt;
    }
    if (
      authorized([
        innsyn.serviceavdelingVaktsentralen,
        innsyn.vaktsentralen,
        innsyn.serviceavdeling,
        innsyn.kemnerkontoret,
        innsyn.seksjonssjefVaktsentralen,
      ]) &&
      window.location.pathname === sitePaths.innsyn.motorvogn.shortUrl
    ) {
      return sitePaths.innsyn.motorvogn.shortUrl;
    }
    return getRedirectPath(sitePaths.innsyn.shortUrl);
  };

  getBackgroundColorLeft = () => {
    // Note: the color scheme follows the rainbow from the top to bottom
    if (window.location.origin.includes("localhost"))
      return "rgba(255, 0, 0, 0.3)";
    if (
      window.location.origin.includes("client-dev") ||
      window.location.origin.includes(".dev.")
    )
      return "rgba(255, 255, 0, 0.3)";
    if (
      window.location.origin.includes("external-client-dev") ||
      window.location.origin.includes(".external-dev.")
    )
      return "rgba(0, 255, 0, 0.3)";
    if (
      window.location.origin.includes("client-test") ||
      window.location.origin.includes(".test.")
    )
      return "rgba(0, 0, 255, 0.3)";
    return "transparent";
  };

  getBackgroundColorRight = () => {
    // Note: the color scheme follows the rainbow from the top to bottom
    const environment = getHostFromEnv("URL_SERVICE");
    if (environment.includes("localhost")) return "rgba(255, 0, 0, 0.3)";
    if (environment.includes("dev")) return "rgba(255, 255, 0, 0.3)";
    if (environment.includes("test")) return "rgba(0, 0, 255, 0.3)";
    return "transparent";
  };

  getBackgroundColor = () => {
    return {
      backgroundImage: `linear-gradient(to right, ${this.getBackgroundColorLeft()}, ${this.getBackgroundColorRight()})`,
    };
  };

  handleLinkClick = (e, url) => {
    e.preventDefault();
    this.props.history.push(url);
  };

  render() {
    const hideNavBar =
      this.props.location.pathname.search(
        "/" +
          "(" +
          "ileggelser/oversikt|" +
          "ileggelser/ettersendelser|" +
          "ileggelser/ettersendelser-til-godkjenning|" +
          "ileggelser/elsparksykkel-gebyr|" +
          "miljogebyrer/oversikt|" +
          "miljogebyrer/oversikt/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/tidskontroller|" +
          "miljogebyrer/til-behandling|" +
          "miljogebyrer/til-behandling/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/tidskontroller|" +
          "miljogebyrer/tidskontrolloversikt|" +
          "miljogebyrer/tidskontrolloversikt/miljogebyrer|" +
          "miljogebyrer/tidskontrolloversikt/miljogebyrer/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/tidskontroller|" +
          "miljogebyrer/tidskontrolloversikt/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/miljogebyrer|" +
          "uregistrerte/kontroller|" +
          "rapporter/ventilstillinger|" +
          "innsyn/motorvogn|" +
          "innsyn/ileggelser|" +
          "innsyn/miljogebyrer|" +
          "klage/ileggelser|" +
          "klage/miljogebyrer|" +
          "klage/miljogebyrer/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/tidskontroller|" +
          "innkreving/ileggelser/venter|" +
          "innkreving/ileggelser/stoppet|" +
          "innkreving/ileggelser/klar|" +
          "innkreving/ileggelser/behandles-innkreving|" +
          "innkreving/ileggelser/feilet|" +
          "innkreving/ileggelser/vellykket|" +
          "innkreving/miljogebyr/venter|" +
          "innkreving/miljogebyr/stoppet|" +
          "innkreving/miljogebyr/klar|" +
          "innkreving/miljogebyr/behandles-innkreving|" +
          "innkreving/miljogebyr/feilet|" +
          "innkreving/miljogebyr/vellykket|" +
          ")" +
          "/" +
          "(" +
          "[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$|" +
          "utskrift|" +
          "[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/utskrift|" +
          "[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/svar-ut/|[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/historikk|" +
          "/admin" +
          ")"
      ) === 0;
    if (hideNavBar) {
      return null;
    }

    const { auth, history } = this.props;

    return (
      <Navbar
        className="navbar-bym no-print"
        expand="lg"
        style={this.getBackgroundColor()}
      >
        <Navbar.Brand>
          <Link to="/">
            <Image
              className="byvaap-cmyk"
              src={byvaapen}
              srcSet={`${byvaapen2x} 2x,${byvappen3x} 3x`}
            />
            <div className="brand-post-text">| HGK</div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          {auth?.isAuthenticated && (
            <Nav className="ml-auto" activeKey={window.location.pathname}>
              {authorized([
                ...Object.values(bypatruljen),
                ...Object.values(juridisk),
                stedfortrederDriftsleder,
              ]) && (
                <Nav.Link
                  href={this.getIleggelseNavigationPath()}
                  onClick={(e) =>
                    this.handleLinkClick(e, this.getIleggelseNavigationPath())
                  }
                >
                  Ileggelse
                </Nav.Link>
              )}
              {authorized([
                ...Object.values(bypatruljen),
                ...Object.values(juridisk),
                stedfortrederDriftsleder,
              ]) && (
                <Nav.Link
                  href={this.getMiljogebyrNavigationPath()}
                  onClick={(e) =>
                    this.handleLinkClick(e, this.getMiljogebyrNavigationPath())
                  }
                >
                  Miljøgebyr
                </Nav.Link>
              )}
              {authorized([
                ...Object.values(bypatruljen),
                ...Object.values(juridisk),
                innsyn.vaktsentralen,
              ]) && (
                <Nav.Link
                  href={this.getUregistrerteNavigationPath()}
                  onClick={(e) =>
                    this.handleLinkClick(
                      e,
                      this.getUregistrerteNavigationPath()
                    )
                  }
                >
                  Uregistrerte
                </Nav.Link>
              )}
              {authorized([
                bypatruljen.saksbehandler,
                bypatruljen.saksbehandleradmin,
              ]) && (
                <Nav.Link
                  href={this.getInnkrevingNavigationPath()}
                  onClick={(e) =>
                    this.handleLinkClick(e, this.getInnkrevingNavigationPath())
                  }
                >
                  Innkreving
                </Nav.Link>
              )}
              {authorized([
                bypatruljen.saksbehandleradmin,
                ...Object.values(juridisk),
              ]) && (
                <Nav.Link
                  href={sitePaths.klage.default}
                  onClick={(e) =>
                    this.handleLinkClick(e, sitePaths.klage.default)
                  }
                >
                  Klage
                </Nav.Link>
              )}
              {authorized([
                bypatruljen.saksbehandler,
                bypatruljen.saksbehandleradmin,
                bypatruljen.avdelingdirektor,
                bypatruljen.seksjonssjefStab,
                bypatruljen.seksjonssjef,
                bypatruljen.driftleder,
                ...Object.values(juridisk),
                innsyn.serviceavdelingVaktsentralen,
                innsyn.vaktsentralen,
                innsyn.serviceavdeling,
                innsyn.seksjonssjefVaktsentralen,
              ]) && (
                <Nav.Link
                  href={this.getGrunndataNavigationPath()}
                  onClick={(e) =>
                    this.handleLinkClick(e, this.getGrunndataNavigationPath())
                  }
                >
                  Grunndata
                </Nav.Link>
              )}
              {authorized([
                ...Object.values(bypatruljen),
                ...Object.values(juridisk),
                skiftlogg,
                stedfortrederDriftsleder,
              ]) && (
                <Nav.Link
                  href={this.getRapporterNavigationPath()}
                  onClick={(e) =>
                    this.handleLinkClick(e, this.getRapporterNavigationPath())
                  }
                >
                  Rapporter
                </Nav.Link>
              )}
              {authorized([
                ...Object.values(bypatruljen),
                ...Object.values(juridisk),
                innsyn.serviceavdelingVaktsentralen,
                innsyn.vaktsentralen,
                innsyn.serviceavdeling,
                innsyn.kemnerkontoret,
                innsyn.seksjonssjefVaktsentralen,
                stedfortrederDriftsleder,
              ]) && (
                <Nav.Link
                  href={this.getInnsynNavigationPath()}
                  onClick={(e) =>
                    this.handleLinkClick(e, this.getInnsynNavigationPath())
                  }
                >
                  Innsyn
                </Nav.Link>
              )}
              {authorized([
                ...bypatruljenAndJuridiskArray,
                innsyn.seksjonssjefVaktsentralen,
                innsyn.serviceavdelingVaktsentralen,
                innsyn.vaktsentralen,
                innsyn.serviceavdeling,
              ]) && (
                <Nav.Link
                  href={sitePaths.linker}
                  onClick={(e) => this.handleLinkClick(e, sitePaths.linker)}
                >
                  Linker
                </Nav.Link>
              )}
              <UserNavItem history={history} />
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavigationBar;
