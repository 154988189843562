// - Logg inn (section start)
export const loggInn = "Logg inn";
// - Logg inn (section end)

// - GlemtPassord (section start)
export const glemtPassord = "Glemt passord";
// - GlemtPassord (section end)

// - Admin (section start)
export const admin = "Admin";
// - Admin (section end)

// - Profil (section start)
export const profil = "Profil";
// - Profil (section end)

// - Detalj (section start)
export const ileggelseDetalj = (ileggelsesnummer) =>
  ileggelsesnummer ? `${ileggelsesnummer} - Ileggelse` : "Ileggelse";
export const miljogebyrDetalj = (ileggelsesnummer) =>
  ileggelsesnummer ? `${ileggelsesnummer} - Miljøgebyr` : "Miljøgebyr";
export const tidskontrollDetalj = (nummer) =>
  nummer
    ? `${nummer} - Tidskontroll - Miljøgebyr`
    : "Tidskontroll - Miljøkontroll";

export const ventilstillingsdetalj = (ileggelsesnummer) =>
  ileggelsesnummer
    ? `${ileggelsesnummer} - Ventilstillingskontroll - Rapporter`
    : "Ventilstillingskontroll - Rapporter";
// - Detalj (section end)

// - Ileggelser (section start)
export const ileggelse = {
  elsparksykkelGebyr: "ELS - Ileggelse",
  ettersendelser: "Ettersendelser - Ileggelse",
  ettersendelserTilGodkjenning: "Ettersendelser til godkjenning - Ileggelse",
  ileggelsesoversikt: "Ileggelsesoversikt - Ileggelse",
};
// - Ileggelser (section end)

// - Miljøgebyr (section start)
export const miljogebyr = {
  miljogebyroversikt: "Miljøgebyroversikt - Miljøgebyr",
  tilBehandling: "Til behandling - Miljøgebyr",
  tidskontroll: "Tidskontroll - Miljøgebyr",
};
// - Miljøgebyr (section end)

// - Uregistrerte (section start)
export const uregistrerte = {
  kontroller: "Kontroller - Uregistrerte",
};
// - Uregistrerte (section end)

// - Grunndata (section start)
export const grunndata = "Grunndata";
export const grunndataAktorer = "Aktører - Grunndata";
export const grunndataBupatruljen = "Bypatruljen - Grunndata";
export const grunndataFarger = "Farger - Grunndata";
export const grunndataForsinketMakulering = "Forsinket makulering - Grunndata";
export const grunndataInternkoderIleggelser =
  "Internkoder ileggelser - Grunndata";
export const grunndataInternkoderMiljøgebyr =
  "Internkoder miljøgebyr - Grunndata";
export const grunndataKjoretoyMerker = "Kjoretoymerker - Grunndata";
export const grunndataKjoretoyTyper = "Kjoretoytyper - Grunndata";
export const grunndataNasjoner = "Nasjoner - Grunndata";
export const overtredelseskoderIleggelser =
  "Overtredelseskoder ileggelser - Grunndata";
export const overtredelseskoderMiljogebyr =
  "Overtredelseskoder miljøgebyr - Grunndata";
export const grunndataRettekoder = "Rettekoder - Grunndata";
export const grunndataVedtakskoder = "Vedtakskoder - Grunndata";
// - Grunndata (section end)

// - Innkreving (section start)
export const innkreving = (pathname) => {
  switch (pathname) {
    // ileggelser
    case "/innkreving/ileggelser/venter":
      return "Innkreving - Ileggelser - Venter";
    case "/innkreving/ileggelser/stoppet":
      return "Innkreving - Ileggelser - Stoppet";
    case "/innkreving/ileggelser/klar":
      return "Innkreving - Ileggelser - Klar";
    case "/innkreving/ileggelser/behandles-innkreving":
      return "Innkreving - Ileggelser - Behandles hos Innkreving";
    case "/innkreving/ileggelser/feilet":
      return "Innkreving - Ileggelser - Feilet";
    case "/innkreving/ileggelser/vellykket":
      return "Innkreving - Ileggelser - Vellykket";

    // miljogebyr
    case "/innkreving/miljogebyr/venter":
      return "Innkreving - Miljøgebyr - Venter";
    case "/innkreving/miljogebyr/stoppet":
      return "Innkreving - Miljøgebyr - Stoppet";
    case "/innkreving/miljogebyr/klar":
      return "Innkreving - Miljøgebyr - Klar";
    case "/innkreving/miljogebyr/behandles-innkreving":
      return "Innkreving - Miljøgebyr - Behandles hos Innkreving";
    case "/innkreving/miljogebyr/feilet":
      return "Innkreving - Miljøgebyr - Feilet";
    case "/innkreving/miljogebyr/vellykket":
      return "Innkreving - Miljøgebyr - Vellykket";

    default:
      break;
  }
};
// - Innkreving (section end)

// - Innsyn (section start)
export const innsyn = (page) => {
  switch (page) {
    case "ileggelser":
      return "Ileggelsesøk - Innsyn";
    case "miljøgebyr":
      return "Miljøgebyrsøk - Innsyn";
    case "motorvogn":
      return "Motorvogn - Innsyn";
    default:
      break;
  }
  return "";
};
export const innsynIleggelseDetalj = (ileggelsesnummer) =>
  `${ileggelsesnummer} - Ileggelse - Innsyn`;
export const innsynMiljogebyrDetalj = (ileggelsesnummer) =>
  `${ileggelsesnummer} - Miljøgebyr - Innsyn`;
// - Innsyn (section end)

// - Klage (section start)
export const klage = "Klage";
// - Klage (section end)

// - Rapporter (section start)
export const rapporter = {
  piggdekkontroll: "Piggdekkontroll - Rapporter",
  skiftlogg: "Skiftlogg - Rapporter",
  ventilstillingskontroll: "Ventilstillingskontroll - Rapporter",
  antallIleggelserPerIleggelsetype:
    "Antall ileggelser per ileggelsetype - Rapporter",
  aarsrapport: "Årsrapport",
  makuleringer: "Makuleringer - Rapporter",
  uferdigstilteKlagesaker: "Uferdigstilte klagesaker - Rapporter",
  klager: "Klager - Rapporter",
  juridiskSaksbehandler: "Juridisk saksbehandeler - Rapporter",
};

export const linker = "Linker";
// - Rapporter (section end)
