import React, { useState, useEffect } from "react";

import TextInput from "../../Common/Forms/TextInput";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import DropDown from "../../Common/Forms/DropDown";
import DropDownListSearch from "../../Common/Forms/DropdownListSearchable";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";

import { visAlleJaNei } from "../../../constants/globals";

const MiljokontrollOversiktFilter = ({
  miljokontrollOversiktFilterForm,
  overtredelseskoder,
  getOvertredelseskodeParagraf,
  handleBlur,
  handleSubmit,
  handleChangeToInitState,
  getOvertredelseskoderMiljogebyr,
}) => {
  const [filterState, setFilterState] = useState(
    miljokontrollOversiktFilterForm
  );
  const [showSpinnerState, setShowSpinnerState] = useState(false);
  const [validationErrorState, setValidationErrorState] = useState({});

  useEffect(() => {
    setFilterState(miljokontrollOversiktFilterForm);
  }, [miljokontrollOversiktFilterForm]);

  useEffect(() => {
    // overtredelseskoder.lenghth because at least "Velg" exists in the array
    if (overtredelseskoder.length === 1) {
      getOvertredelseskoderMiljogebyr();
    }
  }, [overtredelseskoder, getOvertredelseskoderMiljogebyr]);

  return (
    <form
      className="ileggelse-filter-container"
      onSubmit={(e) =>
        handleSubmit(
          e,
          filterState,
          validationErrorState,
          setValidationErrorState,
          setShowSpinnerState
        )
      }
    >
      <h1>Søk etter tidskontroller</h1>
      <div className="filter-flexbox-container ettersendelse-filter-flexbox-item">
        <TextInput
          label="Ileggelsesnummer"
          name="ileggelsesnummer"
          onChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
          onBlur={(e) => handleBlur(e, filterState, setFilterState)}
          value={filterState.ileggelsesnummer}
        />
        <DatetimePicker
          id="fraDato"
          name="fraDato"
          locale="nb"
          label="Dato fra"
          placeholder="Velg"
          onChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
          value={filterState.fraDato}
          timeFormat={false}
          validationError={validationErrorState.fraDatoValidationErrorText}
        />
        <DatetimePicker
          id="fraTid"
          name="fraTid"
          locale="nb"
          label="Tid fra"
          placeholder="Velg"
          onChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
          value={filterState.fraTid}
          dateFormat={false}
          validationError={validationErrorState.fraTidValidationErrorText}
        />
        <DatetimePicker
          id="tilDato"
          name="tilDato"
          locale="nb"
          label="Dato til"
          placeholder="Velg"
          onChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
          value={filterState.tilDato}
          timeFormat={false}
          validationError={validationErrorState.tilDatoValidationErrorText}
        />
        <DatetimePicker
          id="tilTid"
          name="tilTid"
          locale="nb"
          label="Tid til"
          placeholder="Velg"
          onChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
          value={filterState.tilTid}
          dateFormat={false}
          validationError={validationErrorState.tilTilValidationErrorText}
        />
        <TextInput
          label="Tjenestenummer"
          name="tjenestenummer"
          onChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
          onBlur={(e) => handleBlur(e, filterState, setFilterState)}
          value={filterState.tjenestenummer}
        />
        <div>
          <DropDown
            id="hasMiljogebyrId"
            label="Knyttet til miljogebyr"
            name="hasMiljogebyrId"
            selected={filterState.hasMiljogebyrId}
            items={visAlleJaNei}
            onChange={(e) =>
              setFilterState({
                ...filterState,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div>
          <DropDown
            id="avsluttet"
            label="Lukket"
            name="avsluttet"
            selected={filterState.avsluttet}
            items={visAlleJaNei}
            onChange={(e) =>
              setFilterState({
                ...filterState,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <TextInput
          label="Gatenavn/Ved"
          name="gatenavn"
          onChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
          onBlur={(e) =>
            setFilterState({
              ...filterState,
              [e.target.name]: e.target.value.trim(),
            })
          }
          value={filterState.gatenavn}
        />
        <TextInput
          label="Gatenummer/Ved nr."
          name="gatenummer"
          onChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
          onBlur={(e) =>
            setFilterState({
              ...filterState,
              [e.target.name]: e.target.value.trim(),
            })
          }
          value={filterState.gatenummer}
        />
        <TextInput
          label="Bydel"
          name="bydel"
          onChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
          onBlur={(e) =>
            setFilterState({
              ...filterState,
              [e.target.name]: e.target.value.trim(),
            })
          }
          value={filterState.bydel}
        />
        <DropDownListSearch
          label="Overtredelseskode"
          name="overtredelseskode"
          title={
            getOvertredelseskodeParagraf(filterState.overtredelseskode) ||
            "Velg"
          }
          items={overtredelseskoder}
          selected={filterState.overtredelseskode}
          handleChange={(e) =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <FormButtonsForCloseAndSubmit
          submitText="Søk"
          onClose={handleChangeToInitState}
          showSubmitSpinner={showSpinnerState}
        />
      </div>
    </form>
  );
};

export default MiljokontrollOversiktFilter;
