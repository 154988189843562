import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { withAuth } from "react-oidc-context";

class PublicRoute extends Component {
  static propTypes = {
    forcesePublicRoute: PropTypes.bool,
  };

  static defaultProps = {
    forcesePublicRoute: false,
  };

  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} render={this.renderRoute} />;
  }

  renderRoute = (props) => {
    const { component: Comp, auth, forcesePublicRoute } = this.props;
    if (!auth.isAuthenticated || forcesePublicRoute) {
      return <Comp {...props} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      );
    }
  };
}

export default withAuth(PublicRoute);
