import { combineReducers } from "redux";

import aarsrapportFilterFormReducer from "./aarsrapportFilterFormReducer";
import antallIleggelserPerIleggelsetypeFilterFormReducer from "./antallIleggelserPerIleggelsetypeFilterFormReducer";
import grunndataFilterFormReducer from "./grunndataFilterFormReducer";
import ileggelserOversiktFilterFormReducer from "./ileggelserOversiktFilterFormReducer";
import ettersendelserFilterFormReducer from "./ettersendelserFilterFormReducer";
import elsparksykkelGebyrFilterFormReducer from "./elsparksykkelGebyrFilterFormReducer";
import miljogebyrerOversiktFilterFormReducer from "./miljogebyrerOversiktFilterFormReducer";
import miljogebyrTilBehandlingFilterFormReducer from "./miljogebyrTilBehandlingFilterFormReducer";
import miljokontrollOversiktFilterFormReducer from "./miljokontrollOversiktFilterFormReducer";
import uregistrertekontrollerFilterFormReducer from "./uregistrertekontrollerFilterFormReducer";
import SkiftloggFilterFormReducer from "./SkiftloggFilterFormReducer";
import piggdekkontrollFilterFormReducer from "./piggdekkontrollFilterFormReducer";
import VentilstillingFilterFormReducer from "./VentilstillingFilterFormReducer";
import rapportJuridiskSaksbehandlerFilterFormReducer from "./rapportJuridiskSaksbehandlerFilterFormReducer";
import InnsynIleggelserFilterFormReducer from "./InnsynIleggelserFilterFormReducer";
import InnsynMiljogebyrerFilterFormReducer from "./InnsynMiljogebyrerFilterFormReducer";
import InnsynMotorvognFilterFormReducer from "./innsynMotorvognFilterFormReducer";
import KlageIleggelseFilerFormReducer from "./klageIleggelseFilterFormReducer";
import klageVedtakerFormReducer from "./klageVedtakerFormReducer";
import klageRapportFilterFormReducer from "./KlageRapportFilterFormReducer";
import makuleringerFilterFormReducer from "./makuleringerFilterFormReducer";

export default combineReducers({
  aarsrapportFilterForm: aarsrapportFilterFormReducer,
  antallIleggelserPerIleggelsetypeFilterForm:
    antallIleggelserPerIleggelsetypeFilterFormReducer,
  grunndataFilterForm: grunndataFilterFormReducer,
  ileggelserOversiktFilterForm: ileggelserOversiktFilterFormReducer,
  elsparksykkelGebyrFilterForm: elsparksykkelGebyrFilterFormReducer,
  ettersendelserFilterForm: ettersendelserFilterFormReducer,
  miljogebyrerOversiktFilterForm: miljogebyrerOversiktFilterFormReducer,
  miljogebyrTilBehandlingFilterForm: miljogebyrTilBehandlingFilterFormReducer,
  miljokontrollOversiktFilterForm: miljokontrollOversiktFilterFormReducer,
  uregistrertekontrollerFilterForm: uregistrertekontrollerFilterFormReducer,
  SkiftloggFilterForm: SkiftloggFilterFormReducer,
  piggdekkontrollFilterForm: piggdekkontrollFilterFormReducer,
  VentilstillingFilterForm: VentilstillingFilterFormReducer,
  rapportJuridiskSaksbehandlerFilterForm:
    rapportJuridiskSaksbehandlerFilterFormReducer,
  InnsynIleggelserFilterFormState: InnsynIleggelserFilterFormReducer,
  InnsynMiljogebyrerFilterFormState: InnsynMiljogebyrerFilterFormReducer,
  innsynMotorvognFilterForm: InnsynMotorvognFilterFormReducer,
  KlageIleggelseFilterFormState: KlageIleggelseFilerFormReducer,
  klageVedtakerForm: klageVedtakerFormReducer,
  klageRapportFilterForm: klageRapportFilterFormReducer,
  makuleringerFilterForm: makuleringerFilterFormReducer,
});
