import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";

import DetailPageHeader from "../Common/Detalj/DetailPageHeader";
import IleggelseGridOgKommentarContainer from "../Common/Ileggelser/Detalj/IleggelseGridOgKommentarContainer";
import TilbakemeldingerFraBybetjenterContainer from "../Common/Ileggelser/Detalj/TilbakemeldingerFraBybetjenterContainer";
import BilderContainer from "../Common/Ileggelser/Detalj/BilderContainer";
import Ettersendelser from "../Common/Ileggelser/Detalj/Ettersendelser";
import NyeEttersendelser from "../Common/Ileggelser/Detalj/NyeEttersendelser";
import KlageinformasjonContainer from "../Common/Ileggelser/Detalj/KlageinformasjonContainer";
import Spinner from "../Common/Spinner";

import KopiContainer from "../Common/Ileggelser/Detalj/KopiContainer";

import Map from "../Common/Maps/MapView";

import { getIleggelse as getIleggelseAction } from "../../actions/ileggelserActions";
import { getIleggelseEttersendelser as getIleggelseEttersendelserAction } from "../../actions/ileggelseEttersendelserActions";
import { getBybetjenter as getBybetjenterAction } from "../../actions/bybetjenterActions";

import * as title from "../../constants/titles";

class InnsynIleggelseDetaljPage extends Component {
  static propTypes = {
    computedMatch: PropTypes.object.isRequired,
    ileggelse: PropTypes.object.isRequired,
    getIleggelse: PropTypes.func.isRequired,
    getIleggelseEttersendelser: PropTypes.func.isRequired,
    getBybetjenter: PropTypes.func.isRequired,
  };

  componentDidMount() {
    let {
      ileggelse,
      getIleggelse,
      getIleggelseEttersendelser,
      getBybetjenter,
    } = this.props;
    window.scrollTo(0, 0);
    document.title = ileggelse.ileggelsesnummer
      ? title.innsynIleggelseDetalj(ileggelse.ileggelsesnummer)
      : document.title;
    getIleggelse(this.props.computedMatch.params.id);
    getIleggelseEttersendelser(this.props.computedMatch.params.id);
    getBybetjenter(this.props.computedMatch.params.id);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.ileggelse.ileggelsesnummer !==
      this.props.ileggelse.ileggelsesnummer
    ) {
      document.title = title.innsynIleggelseDetalj(
        this.props.ileggelse.ileggelsesnummer
      );
    }
  }

  render() {
    if (
      this.props.ileggelse &&
      this.props.ileggelse.id === this.props.computedMatch.params.id
    ) {
      return (
        <div className="ileggelse-content-wrapper">
          <DetailPageHeader
            pageName={"Ileggelse"}
            pageId={String(this.props.ileggelse.ileggelsesnummer)}
            isMakulert={this.props.ileggelse.isMakulert}
          />
          <Tabs
            defaultActiveKey={1}
            id="InnsynnIleggelseTabs"
            className="detailjTab"
            onSelect={() => {
              this.forceUpdate(); // For tab 2 to update.
            }}
          >
            <Tab eventKey={1} title="Ileggelse">
              <div className="ileggelse-tab-content-wrapper">
                <div style={{ marginBottom: 30, marginLeft: 50 }}>
                  <KopiContainer />
                </div>
                <IleggelseGridOgKommentarContainer />
                <TilbakemeldingerFraBybetjenterContainer
                  ileggelseId={this.props.ileggelse.id}
                />
                <BilderContainer ileggelseId={this.props.ileggelse.id} />
                <Ettersendelser />
                <NyeEttersendelser />
                <KlageinformasjonContainer
                  ileggelseId={this.props.ileggelse.id}
                />
              </div>
            </Tab>
            <Tab eventKey={2} title="Kart">
              <Map
                lat={this.props.ileggelse.latitude}
                lng={this.props.ileggelse.longitude}
              />
            </Tab>
          </Tabs>
        </div>
      );
    } else {
      return (
        <div>
          <Spinner isLoading />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelse: state.ileggelse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIleggelse: (ileggelseId) => dispatch(getIleggelseAction(ileggelseId)),
    getIleggelseEttersendelser: (ileggelseId) =>
      dispatch(getIleggelseEttersendelserAction(ileggelseId)),
    getBybetjenter: () => dispatch(getBybetjenterAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnsynIleggelseDetaljPage);
