import EttersendelserCommon from "../../Detalj/Ettersendelser";
import { useDispatch, useSelector } from "react-redux";

import { getIleggelseEttersendelserEttersendelsebrevPdf } from "../../../../actions/ileggelseEttersendelsebrevActions";

const Ettersendelser = () => {
  const ettersendelser = useSelector((store) =>
    getEttersendelser(store.ileggelseEttersendelser)
  );

  const dispatch = useDispatch();

  const handleGetIleggelseEttersendelserEttersendelsebrevPdf = (
    ileggelseEttersendelseBrevId
  ) => {
    dispatch(
      getIleggelseEttersendelserEttersendelsebrevPdf(
        ileggelseEttersendelseBrevId
      )
    );
  };
  return (
    <EttersendelserCommon
      nyEttersendelse={true}
      hideIfEmpty={true}
      ettersendelser={ettersendelser}
      getEttersendelserEttersendelsebrevPdf={
        handleGetIleggelseEttersendelserEttersendelsebrevPdf
      }
    />
  );
};

const getEttersendelser = (ettersendelser) => {
  ettersendelser &&
    ettersendelser
      .filter((ettersendelse) => !ettersendelse.betjentId)
      .sort((a, b) => {
        if (a.ettersendelseDato > b.ettersendelseDato) {
          return -1;
        } else if (a.ettersendelseDato < b.ettersendelseDato) {
          return 1;
        } else {
          return 0;
        }
      });
};

export default Ettersendelser;
