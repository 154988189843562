import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import NavigationBarContainer from "../NavigationBarContainer";
import SkiftloggFilterContainer from "./SkiftloggFilterContainer";
import SkiftloggExcel from "./SkiftloggExcel";
import SkiftloggList from "./SkiftloggList";
import { getSkiftRapport } from "../../../actions/skiftloggerActions";
import { toIsoString } from "../../../utils/datetimeUtils";
import { downloadBase64 } from "../../../utils/fileUtils";
import * as title from "../../../constants/titles";

class SkiftloggPage extends Component {
  static defaultProps = {
    skiftlogger: {},
  };

  static propTypes = {
    skiftlogger: PropTypes.object,
    getSkiftRapport: PropTypes.func,
    SkiftloggFilterFormState: PropTypes.object,
  };

  componentDidMount() {
    document.title = title.rapporter.skiftlogg;
  }

  generateRapport = async () => {
    const { SkiftloggFilterFormState } = this.props;
    const params = {
      fraDato: toIsoString(SkiftloggFilterFormState.fraDato),
      tilDato: toIsoString(SkiftloggFilterFormState.tilDato),
      teamId: parseInt(SkiftloggFilterFormState.teamId, 10),
      tjenestenummer: parseInt(SkiftloggFilterFormState.tjenestenummer, 10),
    };
    const skiftrapport = await this.props.getSkiftRapport(params);
    if (skiftrapport) downloadBase64(skiftrapport);
  };

  render() {
    const { skiftlogger, skiftloggSubmittedForm } = this.props;
    return (
      <Container fluid>
        <Row>
          <Col>
            <NavigationBarContainer page="skiftlogg" />
            <div className="rapportSearch">
              <SkiftloggFilterContainer />
            </div>
            <div className="rapport-buttons-container">
              <SkiftloggExcel
                skiftloggSubmittedForm={skiftloggSubmittedForm}
                skiftlogger={skiftlogger}
              />
              <div className="pull-right" style={{ paddingTop: 20 }}>
                <b>{skiftlogger.list ? skiftlogger.list.length : 0} Rader</b>
              </div>
            </div>
            <div className="clearfix" />
            <div className="rapport-table">
              <SkiftloggList body={skiftlogger} />
            </div>
          </Col>
          <div style={{ height: 30 }} />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  skiftlogger: state.skiftlogger,
  SkiftloggFilterFormState: state.forms.SkiftloggFilterForm,
  skiftloggSubmittedForm: state.submittedForms.skiftloggSubmittedForm,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSkiftRapport }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SkiftloggPage);
