import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tab, Tabs } from "react-bootstrap";

import DetailPageHeader from "../../Common/Detalj/DetailPageHeader";
import ButtonsContainer from "./ButtonsContainer";
import MiljogebyrGridOgKommentarContainer from "../../Common/Miljogebyr/Detalj/MiljogebyrGridOgKommentarContainer";
import TilbakemeldingerFraBybetjenterContainer from "../../Common/Miljogebyr/Detalj/TilbakemeldingerFraBybetjenterContainer";
import MottakerAvMiljogebyrContainer from "../../Common/Miljogebyr/Detalj/MottakerAvMiljogebyrContainer";
import MiljokontrollContainer from "../../Common/Miljogebyr/Detalj/MiljokontrollContainer";
import MiljokontrollKommentarerContainer from "../../Common/Miljogebyr/Detalj/MiljokontrollKommentarerContainer";
import FolgebrevContainer from "./FolgebrevContainer";
import BilderContainer from "../../Common/Miljogebyr/Detalj/BilderContainer";
import BilderAndMiljokontrollsobservasjonsbilderContainer from "../../Common/Miljogebyr/Detalj/BilderAndMiljokontrollsobservasjonsbilderContainer";
import BilderButtonsContainer from "./BilderButtonsContainer";
import Ettersendelser from "../../Common/Miljogebyr/Detalj/Ettersendelser";
import NyeEttersendelser from "../../Common/Miljogebyr/Detalj/NyeEttersendelser";
import KlageinformasjonContainer from "../../Common/Miljogebyr/Detalj/KlageinformasjonContainer";
import SystemloggContainer from "./SystemloggContainer";
import MakuleringsarsakContainer from "./MakuleringsarsakContainer";
import HistorikkOgKommentarerContainer from "./HistorikkOgKommentarerContainer";
import Map from "../../Common/Maps/MapView";
import Spinner from "../../Common/Spinner";

import { getMiljogebyr } from "../../../actions/miljogebyrerActions";
import { getMiljogebyrEttersendelser } from "../../../actions/miljogebyrEttersendelserActions";
import { getBybetjenter } from "../../../actions/bybetjenterActions";

import { authorized } from "../../../utils/authorizationUtils";

import { bypatruljen } from "../../../constants/roles";

import * as title from "../../../constants/titles";
import { text } from "../../../constants/text";

import "./MiljogebyrDetaljerPage.css";

class MiljogebyrDetaljerPage extends Component {
  static propTypes = {
    computedMatch: PropTypes.object,
    miljogebyr: PropTypes.object,
    getMiljogebyr: PropTypes.func.isRequired,
    getMiljogebyrEttersendelser: PropTypes.func.isRequired,
    getBybetjenter: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoadingMiljokontrollBilder: false,
    };
  }

  setElementsToNoPrint = () => {
    const tabsMiljogebyrKartElement = document.getElementById(
      "tabs-miljogebyr-kart"
    );
    if (!tabsMiljogebyrKartElement) {
      return;
    }
    const navTabsElement = tabsMiljogebyrKartElement.getElementsByTagName("ul");
    if (navTabsElement && navTabsElement.length > 0) {
      navTabsElement[0].setAttribute("class", "nav nav-tabs no-print");
    }
  };

  componentDidMount() {
    const { getMiljogebyr, getBybetjenter, getMiljogebyrEttersendelser } =
      this.props;
    document.title = title.miljogebyrDetalj();
    window.scrollTo(0, 0);
    const miljogebyrId = this.props.computedMatch.params.id;
    getMiljogebyr(miljogebyrId)
      .then((result) => {
        document.title = title.miljogebyrDetalj(result.ileggelsesnummer);
      })
      .catch(() => (document.title = title.miljogebyrDetalj()));
    getMiljogebyrEttersendelser(miljogebyrId);
    getBybetjenter({ onlyActive: false });
  }

  componentDidUpdate = () => {
    this.setElementsToNoPrint();
  };

  toggleIsLoadingMiljokontrollBilder = () => {
    this.setState((state) => ({
      isLoadingMiljokontrollBilder: !state.isLoadingMiljokontrollBilder,
    }));
  };

  render() {
    const { miljogebyr, computedMatch } = this.props;
    const { isLoadingMiljokontrollBilder } = this.state;
    if (miljogebyr && miljogebyr.id === computedMatch.params.id) {
      return (
        <div className="miljogebyr-content-wrapper">
          <DetailPageHeader
            pageName={text.miljoGebyr.pageName}
            pageId={String(miljogebyr.ileggelsesnummer)}
            isMakulert={miljogebyr.isMakulert}
          />
          <Tabs
            defaultActiveKey={1}
            id="tabs-miljogebyr-kart"
            className="detailjTab"
            onSelect={() => {
              this.forceUpdate(); // For tab 2 to update.
            }}
          >
            <Tab eventKey={1} title="Miljøgebyr">
              <div className="miljogebyr-tab-content-wrapper">
                <ButtonsContainer miljogebyrId={computedMatch.params.id} />
                <MiljogebyrGridOgKommentarContainer miljogebyr={miljogebyr} />
                <TilbakemeldingerFraBybetjenterContainer
                  miljogebyrId={computedMatch.params.id}
                />
                <MottakerAvMiljogebyrContainer
                  miljogebyrId={computedMatch.params.id}
                />
                {authorized([
                  bypatruljen.saksbehandleradmin,
                  bypatruljen.saksbehandler,
                ]) && (
                  <FolgebrevContainer miljogebyrId={computedMatch.params.id} />
                )}
                {miljogebyr && miljogebyr.miljoKontrollId && (
                  <>
                    <MiljokontrollContainer
                      miljoKontrollId={miljogebyr.miljoKontrollId}
                      toggleIsLoadingMiljokontrollBilder={
                        this.toggleIsLoadingMiljokontrollBilder
                      }
                    />
                    <MiljokontrollKommentarerContainer
                      miljogebyrId={computedMatch.params.id}
                    />
                    <BilderAndMiljokontrollsobservasjonsbilderContainer
                      miljogebyrId={computedMatch.params.id}
                      BilderButtonsContainer={BilderButtonsContainer}
                      checkboxText="Til ettersendelse"
                      isLoadingMiljokontrollBilder={
                        isLoadingMiljokontrollBilder
                      }
                    />
                  </>
                )}
                {miljogebyr && !miljogebyr.miljoKontrollId && (
                  <BilderContainer
                    miljogebyrId={computedMatch.params.id}
                    BilderButtonsContainer={BilderButtonsContainer}
                    checkbox
                  />
                )}
                <Ettersendelser />
                <NyeEttersendelser />
                <KlageinformasjonContainer
                  miljogebyrId={computedMatch.params.id}
                />
                <SystemloggContainer miljogebyrId={computedMatch.params.id} />
                <MakuleringsarsakContainer
                  miljogebyr={computedMatch.params.id}
                />
                <HistorikkOgKommentarerContainer
                  miljogebyrId={computedMatch.params.id}
                />
              </div>
            </Tab>
            <Tab eventKey={2} title="Kart">
              <div className="miljogebyr-tab-content-wrapper">
                <Map lat={miljogebyr.latitude} lng={miljogebyr.longitude} />
              </div>
            </Tab>
          </Tabs>
        </div>
      );
    }
    return (
      <div>
        <Spinner isLoading />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyr: state.miljogebyr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getMiljogebyr, getMiljogebyrEttersendelser, getBybetjenter },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MiljogebyrDetaljerPage);
