import axios from "../services/axios";
import * as api from "../constants/api";

import { addFlashMessage } from "./FlashMessagesAction";

import { toIsoString } from "../utils/datetimeUtils";

export const GET_UREGISTRERTEKONTROLLER_SUCCESS =
  "GET_UREGISTRERTEKONTROLLER_SUCCESS";
export const GET_UREGISTRERTEKONTROLLER_FAILURE =
  "GET_UREGISTRERTEKONTROLLER_FAILURE";

export function getUregistrertekontroller(params) {
  return async (dispatch) => {
    return axios
      .get(api.GET_UREGISTRERTEKONTROLLER_URL, {
        params: {
          ...params,
          fraDato: params.fraDato && toIsoString(params.fraDato),
          tilDato: params.tilDato && toIsoString(params.tilDato),
        },
      })
      .then((response) => {
        dispatch({
          type: GET_UREGISTRERTEKONTROLLER_SUCCESS,
          payload: response.data.result,
        });

        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch(addFlashMessage({ type: "error", message: error }));
        return Promise.reject(error);
      });
  };
}

export function getSingleUregistrertekontroller(id) {
  return async (dispatch) => {
    return axios
      .get(api.GET_SINGLE_UREGISTRERTEKONTROLLER_URL(id))
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch(addFlashMessage({ type: "error", message: error }));

        return Promise.reject(error);
      });
  };
}

export function getAllUregistrertekontrollerObservasjonsgrupperBilder(id, s3BucketCategory) {
  return async (dispatch) => {
    return axios
      .get(api.GET_ALL_UREGISTRERTEKONTROLLER_OBSERVASJONSGRUPPER_BILDER_URL(id), {params: { S3BucketCategory: s3BucketCategory }})
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch(addFlashMessage({ type: "error", message: error }));

        return Promise.reject(error);
      });
  };
}
