import React from "react";
import PropTypes from "prop-types";
import DropDown from "../../Common/Forms/DropDown";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import DropDownListSearch from "../../Common/Forms/DropdownListSearchable";
import DropDownListMultiCheckboxOptions from "../../Common/Forms/DropDownListMultiCheckboxOptions";
import TextInput from "../../Common/Forms/TextInput";
import LoadButton from "../../Common/LoadButton";

import { authorized } from "../../../utils/authorizationUtils";

import {
  juridisk,
  bypatruljen,
  stedfortrederDriftsleder,
} from "../../../constants/roles";
import {
  ileggelseStatuser,
  makulertStatus,
  nasjonFiltrering,
} from "../../../constants/globals";

export default class FilterForm extends React.Component {
  static propTypes = {
    showSokSpinner: PropTypes.bool,
    ileggelseFilterFormState: PropTypes.shape({
      ileggelsesnummer: PropTypes.string,
      fraDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      tilDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      fraTid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      tilTid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      kjennemerke: PropTypes.string,
      tjenestenummer: PropTypes.string,
      gatenavn: PropTypes.string,
      leveringstypeId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      type: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      status: PropTypes.PropTypes.oneOfType([
        // TODO Endre dette !!!
        PropTypes.number,
        PropTypes.string,
      ]),
      teamId: PropTypes.PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),

      ileggelsesnummerVaildationErrorText: PropTypes.string,
      tjenestenummerValidationErrorText: PropTypes.string,
      saksnummerValidationErrorText: PropTypes.string,
    }),
    overtredelseskoderIleggelser: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        kode: PropTypes.string,
        beskrivelse: PropTypes.string,
        gruppe: PropTypes.number,
        belop: PropTypes.number,
        aktiv: PropTypes.bool,
      })
    ),
    ileggelsestyper: PropTypes.array,
    betjentgrupper: PropTypes.array,
    ileggelseleveringstyper: PropTypes.array,
    ileggelsesstatuser: PropTypes.array,
    saksnummer: PropTypes.string,
    vedtakskoder: PropTypes.array,
    klagekoder: PropTypes.array,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
    handleSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      authorizedOverforingsdatoIne: authorized([
        bypatruljen.saksbehandleradmin,
      ]),
      authorizedSaksnummer: authorized([
        ...Object.values(juridisk),
        bypatruljen.saksbehandleradmin,
      ]),
      authorizedVedtagskode: authorized([
        ...Object.values(juridisk),
        bypatruljen.saksbehandleradmin,
      ]),
      authorizedKlagekode: authorized([
        ...Object.values(juridisk),
        bypatruljen.saksbehandleradmin,
      ]),
      authorizedStatus: !authorized([
        bypatruljen.driftleder,
        stedfortrederDriftsleder,
      ]),
      authorizedTjenestenummer: !authorized([stedfortrederDriftsleder]),
    };
  }

  render() {
    const {
      showSokSpinner,
      ileggelseFilterFormState,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;

    return (
      <form id="ileggelse-filter" onSubmit={handleSubmit}>
        <div
          className="filter-flexbox-container ileggelse-filter-flexbox-item"
          style={{ marginBottom: 10 }}
        >
          <div>
            <TextInput
              label="Ileggelsesnummer"
              name="ileggelsesnummer"
              onChange={handleChange}
              onBlur={handleBlur}
              value={ileggelseFilterFormState.ileggelsesnummer}
              validationError={
                ileggelseFilterFormState.validationError.ileggelsesnummer
              }
            />
          </div>
          <div>
            <DatetimePicker
              id="fraDato"
              name="fraDato"
              locale="nb"
              placeholder="Velg"
              label="Dato fra"
              onChange={handleChange}
              value={ileggelseFilterFormState.fraDato}
              validationError={
                ileggelseFilterFormState.fraDatoValidationErrorText
              }
              timeFormat={false}
            />
          </div>
          <div>
            <DatetimePicker
              id="fraTid"
              name="fraTid"
              locale="nb"
              label="Tid fra"
              placeholder="Velg"
              onChange={handleChange}
              value={ileggelseFilterFormState.fraTid}
              validationError={
                ileggelseFilterFormState.fraTidValidationErrorText
              }
              dateFormat={false}
            />
          </div>
          <div>
            <DatetimePicker
              id="tilDato"
              name="tilDato"
              locale="nb"
              label="Dato til"
              placeholder="Velg"
              onChange={handleChange}
              value={ileggelseFilterFormState.tilDato}
              validationError={
                ileggelseFilterFormState.tilDatoValidationErrorText
              }
              timeFormat={false}
            />
          </div>
          <div>
            <DatetimePicker
              id="tilTid"
              name="tilTid"
              locale="nb"
              label="Tid til"
              placeholder="Velg"
              onChange={handleChange}
              value={ileggelseFilterFormState.tilTid}
              validationError={
                ileggelseFilterFormState.tilTidValidationErrorText
              }
              dateFormat={false}
            />
          </div>
          {this.state.authorizedTjenestenummer && (
            <div>
              <TextInput
                label="Tjenestenummer"
                name="tjenestenummer"
                onChange={handleChange}
                onBlur={handleBlur}
                value={ileggelseFilterFormState.tjenestenummer}
                validationError={
                  ileggelseFilterFormState.validationError.tjenestenummer
                }
              />
            </div>
          )}

          <div>
            <DropDown
              label="Venter"
              name="isVenter"
              items={makulertStatus}
              selected={ileggelseFilterFormState.isVenter}
              title="Velg"
              onChange={handleChange}
            />
          </div>
          <div>
            <DropDown
              label="Makulert"
              name="isMakulert"
              items={makulertStatus}
              selected={ileggelseFilterFormState.isMakulert}
              title="Velg"
              onChange={handleChange}
            />
          </div>
        </div>
        <div
          className="filter-flexbox-container ileggelse-filter-flexbox-item"
          style={{ marginBottom: 10 }}
        >
          <div style={{ marginBottom: 15 }}>
            <label htmlFor="kjennemerke">Kjennemerke</label>
            <input
              id="kjennemerke"
              name="kjennemerke"
              className="form-control"
              type="text"
              value={ileggelseFilterFormState.kjennemerke}
              onChange={handleChange}
            />
          </div>
          <div>
            <DropDown
              label="Nasjon"
              name="nasjon"
              items={nasjonFiltrering}
              selected={ileggelseFilterFormState.nasjon}
              title="Velg"
              onChange={handleChange}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <label htmlFor="gatenavn">Gatenavn</label>
            <input
              id="gatenavn"
              name="gatenavn"
              className="form-control"
              type="text"
              value={ileggelseFilterFormState.gatenavn}
              onChange={handleChange}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <label htmlFor="gatenummer">Gatenummer</label>
            <input
              id="gatenummer"
              name="gatenummer"
              className="form-control"
              type="text"
              value={ileggelseFilterFormState.gatenummer}
              onChange={handleChange}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <label htmlFor="bydel">Bydel</label>
            <input
              id="bydel"
              name="bydel"
              className="form-control"
              type="text"
              value={ileggelseFilterFormState.bydel}
              onChange={handleChange}
            />
          </div>
          <DropDownListSearch
            id="overtredelsekode"
            label="Overtredelseskode"
            name="overtredelse"
            title={
              this.props.ileggelseFilterFormState.overtredelse
                ? this.props.ileggelseFilterFormState.overtredelse
                : "Velg"
            }
            items={[{ id: "", navn: "Velg", beskrivelse: "" }].concat(
              this.props.overtredelseskoderIleggelser.map((ok) => {
                return {
                  id: ok.id,
                  navn: ok.id.toString(),
                  beskrivelse: ok.beskrivelse,
                };
              })
            )}
            selected={this.props.ileggelseFilterFormState.overtredelse}
            handleChange={handleChange}
          />
          <div>
            <label htmlFor="leveringstypeId">Leveringstype</label>
            <DropDown
              id="leveringstypeId"
              name="leveringstypeId"
              title="Velg"
              selected={ileggelseFilterFormState.leveringstypeId}
              items={this.props.ileggelseleveringstyper}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="type">Ileggelsestype</label>
            <DropDown
              id="type"
              name="type"
              title="Velg"
              selected={ileggelseFilterFormState.type}
              items={this.props.ileggelsestyper}
              onChange={handleChange}
            />
          </div>
          {this.state.authorizedStatus && (
            <div style={{ marginBottom: 10 }}>
              <DropDownListMultiCheckboxOptions
                id="status"
                label="Status"
                name="selectedStatuser"
                items={ileggelseStatuser}
                selected={this.props.ileggelseFilterFormState.selectedStatuser}
                title={
                  this.props.ileggelseFilterFormState.selectedStatuser.length >
                  0
                    ? `${this.props.ileggelseFilterFormState.selectedStatuser.length} valg`
                    : "Velg"
                }
                onChange={handleChange}
              />
            </div>
          )}
          {this.state.authorizedOverforingsdatoIne && (
            <>
              <div>
                <DatetimePicker
                  id="fraIneOverforingsdato"
                  name="fraIneOverforingsdato"
                  locale="nb"
                  placeholder="Velg"
                  label="INE overføringsdato fra"
                  onChange={handleChange}
                  value={ileggelseFilterFormState.fraIneOverforingsdato}
                  validationError={
                    ileggelseFilterFormState.fraIneOverforingsdatoValidationErrorText
                  }
                  timeFormat={false}
                />
              </div>
              <div>
                <DatetimePicker
                  id="tilIneOverforingsdato"
                  name="tilIneOverforingsdato"
                  locale="nb"
                  placeholder="Velg"
                  label="INE overføringsdato til"
                  onChange={handleChange}
                  value={ileggelseFilterFormState.tilIneOverforingsdato}
                  validationError={
                    ileggelseFilterFormState.tilIneOverforingsdatoValidationErrorText
                  }
                  timeFormat={false}
                />
              </div>
            </>
          )}
        </div>
        {(this.state.authorizedSaksnummer ||
          this.state.authorizedVedtagskode ||
          this.state.authorizedKlagekode) && (
          <div
            className="filter-flexbox-container ileggelse-filter-flexbox-item"
            style={{ marginBottom: 10 }}
          >
            {this.state.authorizedSaksnummer && (
              <TextInput
                label="Saksnummer"
                name="saksnummer"
                onChange={handleChange}
                onBlur={handleBlur}
                value={ileggelseFilterFormState.saksnummer}
                validationError={
                  ileggelseFilterFormState.validationError.saksnummer
                }
              />
            )}
            {this.state.authorizedVedtagskode && (
              <DropDownListSearch
                id="vedtakskode"
                label="Vedtakskode"
                name="vedtakskode"
                title={
                  this.props.ileggelseFilterFormState.vedtakskode
                    ? this.props.ileggelseFilterFormState.vedtakskode
                    : "Velg"
                }
                items={this.props.vedtakskoder}
                selected={this.props.ileggelseFilterFormState.vedtakskode}
                handleChange={handleChange}
              />
            )}
            {this.state.authorizedKlagekode && (
              <DropDown
                id="klagekode"
                name="klagekode"
                label="Klagekode"
                title="Velg"
                selected={ileggelseFilterFormState.klagekode}
                items={this.props.klagekoder}
                onChange={handleChange}
              />
            )}
          </div>
        )}
        <div>
          <LoadButton
            buttonProps={{
              onClick: this.props.handleChangeToInitState,
              className: "btn-link",
              style: { height: 40, paddingRight: 17, paddingLeft: 0 },
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            Tilbakestill
          </LoadButton>
          <LoadButton
            isLoading={showSokSpinner}
            buttonProps={{
              type: "submit",
              className: "btn-bym-action btn-bym-large",
              disabled: showSokSpinner,
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            Søk
          </LoadButton>
        </div>
      </form>
    );
  }
}
