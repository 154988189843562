import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import SpinLoader from "./SpinLoader";

export const LoadButton = ({
  isLoading,
  buttonProps,
  spinnerProps,
  spinnerMarginLeft,
  children,
}) => {
  const spinLoaderSpanWrapperStyle = spinnerMarginLeft
    ? { display: "inline-block", marginLeft: spinnerMarginLeft }
    : { display: "inline-block", marginLeft: 10 };

  const spinnerLoaderProps = {
    height: 16,
    width: 16,
    thickness: 3,
    pColor: "#0f20f2",
    sColor: "#F0FFFF",
    ...spinnerProps,
  };

  return (
    <Button {...buttonProps}>
      {children}
      {isLoading && (
        <span style={spinLoaderSpanWrapperStyle}>
          <SpinLoader {...spinnerLoaderProps}/>
        </span>
      )}
    </Button>
  );
};

LoadButton.propTypes = {
  isLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  buttonProps: PropTypes.shape({}),
  spinnerProps: PropTypes.shape({}),
  spinnerMarginLeft: PropTypes.number,
};


export default LoadButton;
