import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { map } from "lodash";
import { Link } from "react-router-dom";

import Table from "../../Common/Tables/BaseTable";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";

class VentilstillingList extends Component {
  static propTypes = {
    ventilstillinger: PropTypes.array,
    id: PropTypes.string,
  };

  header = [
    "Dato",
    "Hovedgate",
    "Ilegg.nr.",
    "Tjenestenr.",
    "Kjennemerke",
    "Status",
    "Oppfølging",
  ];

  render() {
    const { id, ventilstillinger } = this.props;

    return (
      <div className="table-wrapper-horizontal-scroll">
        <Table headerRow={this.header} id={id} striped>
          <tbody>
            {map(ventilstillinger, (i, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Link to={i.id}>
                      {toNorwegianDatetime(i.dato).format("DD.MM.YYYY")}
                    </Link>
                  </td>
                  <td>
                    {`${i.gatenavn ? i.gatenavn : ""} ${
                      i.gatenummer ? i.gatenummer : ""
                    }`}
                  </td>
                  <td>{i.ileggelsesnummer}</td>
                  <td>{i.tjenesteNummer}</td>
                  <td>{i.kjennemerke}</td>
                  <td>{i.status ? "Aktiv" : "Inaktiv"}</td>
                  <td>
                    {toNorwegianDatetime(i.oppfolgingDato).format("DD.MM.YYYY")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default VentilstillingList;
