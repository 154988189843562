const rapporterSitePaths = {
  shortUrl: "/rapporter",
  ventilstillinger: {
    shortUrl: "/rapporter/ventilstillinger",
    oversikt: "/rapporter/ventilstillinger/oversikt",
    detalj: {
      bilde:
        "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      shortUrl:
        "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      utskrift:
        "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",
    },
  },
  skiftlogg: {
    shortUrl: "/rapporter/skiftlogg",
    oversikt: "/rapporter/skiftlogg/oversikt",
  },
  piggdekkontroller: {
    shortUrl: "/rapporter/piggdekkontroller",
    oversikt: "/rapporter/piggdekkontroller/oversikt",
  },
  klager: {
    shortUrl: "/rapporter/klager",
    oversikt: "/rapporter/klager/oversikt",
  },
  uferdigstilteKlagesaker: {
    shortUrl: "/rapporter/uferdigstilte-klagesaker",
    oversikt: "/rapporter/uferdigstilte-klagesaker/oversikt",
  },
  juridiskSaksbehandler: {
    shortUrl: "/rapporter/saksbehandler",
    oversikt: "/rapporter/saksbehandler/oversikt",
  },
  antallIleggelserPerIleggelsetype: {
    shortUrl: "/rapporter/antall-ileggelser-per-ileggelsetype",
    oversikt: "/rapporter/antall-ileggelser-per-ileggelsetype/oversikt",
  },
  makuleringer: {
    shortUrl: "/rapporter/makuleringer",
    oversikt: "/rapporter/makuleringer/oversikt",
  },
  aarsrapport: {
    shortUrl: "/rapporter/aarsrapport",
    oversikt: "/rapporter/aarsrapport/oversikt",
  },
};

export default rapporterSitePaths;
