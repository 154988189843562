export const overtredelsekodeForEditereBelop = 247;

export const ettersendelsesrapportMinLength = 5;
export const ettersendelsesrapportMaxLength = 300;

export const miljogebyrStatuserDictionary = {
  isMaKvalitetsikres: "Må kvalitetsikres",
  isTilEttersendelse: "Til ettersendelse",
  isEttersendt: "Ettersendt",
  isKlarTilIne: "Klar til INE",
  isStoppetAvSystemet: "Stoppet av systemet",
  isFeiletKemner: "Feilet INE",
  isSendtIne: "Sendt INE",
  isBetalt: "Har betalingskode",
  isVenter: "Venter",
  isMakulert: "Makulert",
};

export const miljogebyrStatuser = [
  { navn: "Må kvalitetsikres", id: "isMaKvalitetsikres" },
  { navn: "Til ettersendelse", id: "isTilEttersendelse" },
  { navn: "Ettersendt", id: "isEttersendt" },
  { navn: "Klar til INE", id: "isKlarTilIne" },
  { navn: "Stoppet av systemet", id: "isStoppetAvSystemet" },
  { navn: "Feilet INE", id: "isFeiletKemner" },
  { navn: "Sendt INE", id: "isSendtIne" },
  { navn: "Har betalingskode", id: "isBetalt" },
];

export const ileggelseStatuser = [
  { navn: "Til ettersendelse", id: "isTilEttersendelse" },
  {
    navn: "Ettersendelse til godkjenning",
    id: "isEttersendelseTilGodkjenning",
  },
  { navn: "Ettersendt", id: "isEttersendt" },
  { navn: "Klar til INE", id: "isKlarTilIne" },
  { navn: "Stoppet av systemet", id: "isStoppetAvSystemet" },
  { navn: "Feilet INE", id: "isFeiletKemner" },
  { navn: "Sendt INE", id: "isSendtIne" },
  { navn: "Har betalingskode", id: "isBetalt" },
];

export const makulertStatus = [
  { id: 0, navn: "Vis alle" },
  { id: false, navn: "Nei" },
  { id: true, navn: "Ja" },
];

export const visAlleJaNei = [
  { id: null, navn: "Vis alle" },
  { id: false, navn: "Nei" },
  { id: true, navn: "Ja" },
];

export const aktivStatuser = [
  { id: null, navn: "Vis alle" },
  { id: false, navn: "Deaktivert" },
  { id: true, navn: "Aktiv" },
];

export const nasjonFiltrering = [
  { id: 1, navn: "Norge" },
  { id: 2, navn: "Utenlandske" },
  { id: 3, navn: "Usikkert" },
];

export const ileggelsestyperFiltrering = [
  { id: 1, navn: "Gebyr" },
  { id: 2, navn: "Tilleggsgebyr piggdekk" },
  { id: 3, navn: "Vilkårsparkering/Kontrollsanksjon" },
  { id: 4, navn: "Avgift" },
  { id: 5, navn: "Miljøgebyr" },
];

export const betaltkodeDescriptions = {
  1: { description: "Ny saldo har oppstått etter at den er rapportert i 0" },
  5: {
    description:
      "Saldo er 0 som et resultat av noe annet enn en innbetaling, feks. Frafall",
  },
  9: { description: "Saldo er 0 dekket av en innbetaling" },
};

export const ileggelsestyper = {
  gebyr: 1,
  tilleggsgebyrPiggdekk: 2,
  vilkorsparkeringKontrollsanksjon: 3,
  avgift: 4,
  miljogebyr: 5,
};

export const months = [
  "Januar",
  "Februar",
  "Mars",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Desember",
];
