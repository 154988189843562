import React from "react";
import { withAuth } from "react-oidc-context";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { authorized } from "../../utils/authorizationUtils";

import sitePaths from "../../constants/sitePaths";

class AuthorizedRoutes extends Route {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string).isRequired
  };

  render() {
    const { auth, location, roles, component: Com, ...rest } = this.props;

    if (auth.isLoading) {
      return <div></div>
    } else if (auth.isAuthenticated && authorized(roles)) {
      return <Com {...rest} />;
    } else if (auth.isAuthenticated && !authorized(roles)) {
      <div>Not Authorized</div>
    }
    return ( 
      <Redirect to={{
          pathname: sitePaths.login,
          state: { prevLocation: location }
        }}
      />
    )
  }
}

export default withAuth(AuthorizedRoutes);
