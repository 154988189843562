import classnames from "classnames";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import "./HeaderCell.css";
import normalIcon from "../../../images/sortering.svg";
import ascIcon from "../../../images/sortering_ned.svg";
import descIcon from "../../../images/sortering_opp.svg";

function getSortClass(cellKey, sortCellKey, sortOrders) {
  return cellKey !== sortCellKey
    ? "hc-sort-normal"
    : sortOrders === "desc"
    ? "hc-sort-desc"
    : "hc-sort-asc";
}

class HeaderCell extends PureComponent {
  static propTypes = {
    cellKey: PropTypes.string,
    sort: PropTypes.shape({
      cellKey: PropTypes.string.isRequired,
      orders: PropTypes.string,
    }),
    children: PropTypes.node,
    onSort: PropTypes.func,
  };

  handleClick = () => {
    const { cellKey, sort, onSort } = this.props;
    const { cellKey: sortCellKey, orders: sortOrders } = sort || {};
    if (onSort) {
      onSort({
        cellKey: cellKey,
        orders:
          sortCellKey === cellKey && sortOrders === "asc" ? "desc" : "asc",
      });
    }
  };

  render() {
    const { cellKey, sort } = this.props;
    const { cellKey: sortCellKey, orders: sortOrders } = sort || {};
    const sortClass = getSortClass(cellKey, sortCellKey, sortOrders);
    if (sort) {
      return (
        <th
          className="header-cell"
          style={{ whiteSpace: "nowrap" }}
          onClick={this.handleClick}
        >
          {this.props.children}
          <span className={classnames("hc-sort", sortClass)}>
            <Image className="hc-img-normal" src={normalIcon} />
            <Image className="hc-img-asc" src={ascIcon} />
            <Image className="hc-img-desc" src={descIcon} />
          </span>
        </th>
      );
    }
    return <th>{this.props.children}</th>;
  }
}

export default HeaderCell;
