const ileggelser = {
  shortUrl: "/ileggelser",
  oversikt: {
    shortUrl: "/ileggelser/oversikt",
    utskrift: "/ileggelser/oversikt/utskrift",
    detalj: {
      shortUrl: {
        routeMatcher:
          "/ileggelser/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (ileggelseId) => `/ileggelser/oversikt/${ileggelseId}`,
      },
      utskrift:
        "/ileggelser/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",
      toastUi:
        "/ileggelser/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
      bilde:
        "/ileggelser/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      svarUtHistorikk:
        "/ileggelser/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
    },
  },
  ettersendelser: {
    shortUrl: "/ileggelser/ettersendelser",
    utskrift: "/ileggelser/ettersendelser/utskrift",
    detalj: {
      shortUrl: {
        routeMatcher:
          "/ileggelser/ettersendelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (ileggelseId) => `/ileggelser/ettersendelser/${ileggelseId}`,
      },
      utskrift:
        "/ileggelser/ettersendelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",
      toastUi:
        "/ileggelser/ettersendelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
      bilde:
        "/ileggelser/ettersendelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      svarUtHistorikk:
        "/ileggelser/ettersendelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
    },
  },
  ettersendelserTilGodkjenning: {
    shortUrl: "/ileggelser/ettersendelser-til-godkjenning",
    detalj: {
      shortUrl: {
        routeMatcher:
          "/ileggelser/ettersendelser-til-godkjenning/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (ileggelseId) =>
          `/ileggelser/ettersendelser-til-godkjenning/${ileggelseId}`,
      },
      utskrift:
        "/ileggelser/ettersendelser-til-godkjenning/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",
      toastUi:
        "/ileggelser/ettersendelser-til-godkjenning/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
      bilde:
        "/ileggelser/ettersendelser-til-godkjenning/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilde",
      svarUtHistorikk:
        "/ileggelser/ettersendelser-til-godkjenning/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
    },
  },
  elsparksykkelGebyr: {
    shortUrl: "/ileggelser/elsparksykkel-gebyr",
    utskrift: "/ileggelser/elsparksykkel-gebyr/utskrift",
    detalj: {
      shortUrl: {
        routeMatcher:
          "/ileggelser/elsparksykkel-gebyr/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (ileggelseId) =>
          `/ileggelser/elsparksykkel-gebyr/${ileggelseId}`,
      },
      utskrift:
        "/ileggelser/elsparksykkel-gebyr/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",
      toastUi:
        "/ileggelser/elsparksykkel-gebyr/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
      bilde:
        "/ileggelser/elsparksykkel-gebyr/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      svarUtHistorikk:
        "/ileggelser/elsparksykkel-gebyr/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
    },
  },
};

export default ileggelser;
