import React from "react";
import Excel from "../../Common/Excel";

import {
  formatDateHavingNorwegianDateTime,
  formatDateTimeHavingNorwegianDateTime,
} from "../../../utils/datetimeUtils";

const VentilstillingExcel = ({
  ventilstillinger,
  ventilstillingerSubmittedForm,
}) => {
  return (
    <Excel
      element={(onClick) => (
        <button className="btn btn-bym-medium btn-bym-action" onClick={onClick}>
          Lag rapport
        </button>
      )}
      filename={"Ventilstillinger.xlsx"}
      sheets={[
        {
          name: "Ventilstillinger",
          data: ventilstillinger.map((v) => ({
            Dato: formatDateHavingNorwegianDateTime(v.dato),
            Hovedgate: `${v.gatenavn ? v.gatenavn : ""} ${
              v.gatenummer ? v.gatenummer : ""
            }`,
            "Ilegg.nr.": v.ileggelsesnummer,
            "Tjenestenr.": v.tjenesteNummer,
            Kjennemerke: v.kjennemerke,
            Status: v.status ? "Aktiv" : "Inaktiv",
            Oppfølging: formatDateHavingNorwegianDateTime(v.oppfolgingDato),
          })),
        },
        {
          name: "Søkdata",
          data: [
            {
              "Timestamp for søk": formatDateTimeHavingNorwegianDateTime(
                ventilstillingerSubmittedForm.submitTimestamp
              ),
              "Dato fra": formatDateTimeHavingNorwegianDateTime(
                ventilstillingerSubmittedForm.fraDato
              ),
              "Dato til": formatDateTimeHavingNorwegianDateTime(
                ventilstillingerSubmittedForm.tilDato
              ),
              Kjennemerke: ventilstillingerSubmittedForm.kjennemerke,
            },
          ],
        },
      ]}
    />
  );
};

export default VentilstillingExcel;
