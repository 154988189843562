import React from "react";
import { createSelector } from "reselect";
import { toNorwegianDatetime } from "../utils/datetimeUtils";
import { ileggelseClientSpecificUtils } from "../utils/ileggelseClientSpecificUtils";
import { INITIAL_STATE as initEttersendelseQueryParameters } from "../reducers/forms/ettersendelserFilterFormReducer";
import { makulertStatus } from "../constants/globals";

const ettersendelseList = (state) => state.ettersendelser;

export const getEttersendelseList = createSelector(
  ettersendelseList,
  (ettersendelseList) => {
    return ettersendelseList.items;
  }
);

const ettersendelserItems = (state) =>
  state.ettersendelser && state.ettersendelser.items;

const showImageIcon = (ettersendelse) => {
  if (ettersendelse.antallBilder > 1) return "ShowImageIcon";
  return "";
};

export const getEttersendelseTableRows = createSelector(
  [ettersendelserItems],
  (ettersendelserItems) => {
    return ettersendelserItems.map((ettersendelse) => {
      const ileggelsesdatoFra =
        ettersendelse.ileggelsesdatoFra &&
        toNorwegianDatetime(ettersendelse.ileggelsesdatoFra).format(
          "DD.MM.YYYY"
        );
      const ileggelsesdatoFraTid =
        ettersendelse.ileggelsesdatoFra &&
        toNorwegianDatetime(ettersendelse.ileggelsesdatoFra).format("HH:mm");
      const ileggelsesdatoTilTid =
        ettersendelse.ileggelsesdatoTil &&
        ` - ${toNorwegianDatetime(ettersendelse.ileggelsesdatoTil).format(
          "HH:mm"
        )}`;
      const klokkeslett = ileggelsesdatoFraTid + ileggelsesdatoTilTid;
      const rettelser =
        ettersendelse.rettelser &&
        ettersendelse.rettelser.map((rettelse, index) => {
          if (index === 0 && ettersendelse.rettelser.length === 1) {
            return rettelse.kommentar;
          }
          return (
            <React.Fragment key={`ettersendelse.rettelse.kommentar${index}`}>
              <br />
              {`${++index}. ${rettelse.kommentar}`}
            </React.Fragment>
          );
        });
      return {
        metadata: { isMakulert: ettersendelse.isMakulert },
        data: [
          ettersendelse.ileggelsesnummer &&
            ettersendelse.ileggelsesnummer.toString(),
          ileggelsesdatoFra,
          klokkeslett,
          ettersendelse.tjenestenummer &&
            ettersendelse.tjenestenummer.toString(),
          ileggelseClientSpecificUtils.getOvertredelserString(
            ettersendelse.overtredelse1,
            ettersendelse.overtredelse2,
            ettersendelse.overtredelse3
          ),
          ettersendelse.kjennemerke,
          ettersendelse.nasjon,
          ettersendelse.gatenavn,
          ettersendelse.gatenummer,
          showImageIcon(ettersendelse),
          ettersendelse.ettersendelse
            ? ettersendelse.ettersendelse.kommentar
            : "",
          rettelser,
        ],
      };
    });
  }
);

const ettersendelseQueryParameters = (state) =>
  state.forms.ettersendelserFilterForm;

const getNavnById = (id, objectArray) => {
  const object = objectArray.filter((leveringstype) => leveringstype.id === id);
  return object.length > 0 && object[0].navn ? object[0].navn : "";
};

const getIleggelseQueryParameterStatusString = (
  ettersendelseQueryParameters
) => {
  let statuser = "";
  statuser =
    ettersendelseQueryParameters.isVenter === true
      ? `${statuser}Venter, `
      : statuser;
  statuser =
    ettersendelseQueryParameters.isTilEttersendelse === true
      ? `${statuser}Til ettersendelse, `
      : statuser;
  statuser =
    ettersendelseQueryParameters.isEttersendelseTilGodkjenning === true
      ? `${statuser}Ettersendelse til godkjenning, `
      : statuser;
  statuser =
    ettersendelseQueryParameters.isEttersendt === true
      ? `${statuser}Ettersendt, `
      : statuser;
  statuser =
    ettersendelseQueryParameters.isKlarTilKemner === true // Skal stå som isKlarTilKemner tils endret på backend
      ? `${statuser}Klar til INE, `
      : statuser;
  statuser =
    ettersendelseQueryParameters.isSendtKemner === true // Skal stå isSendtKemner tils backend er endret til isSendtIne
      ? `${statuser}Sendt til INE, `
      : statuser;
  statuser =
    ettersendelseQueryParameters.isBetalt === true
      ? `${statuser}Betalt, `
      : statuser;

  return statuser ? statuser.slice(0, statuser.length - 2) : statuser;
};

export const getEttersendelseQueryParameters = createSelector(
  [ettersendelseQueryParameters],
  (ettersendelseQueryParameters) => {
    let changedEttersendelseQueryParameters = [];
    if (
      initEttersendelseQueryParameters.ileggelsesnummer !==
      ettersendelseQueryParameters.ileggelsesnummer
    ) {
      changedEttersendelseQueryParameters = [
        ...changedEttersendelseQueryParameters,
        {
          key: "Ileggelsesnummer",
          value: ettersendelseQueryParameters.ileggelsesnummer,
        },
      ];
    }
    if (
      ettersendelseQueryParameters.fraDato &&
      ettersendelseQueryParameters.tilDato
    ) {
      changedEttersendelseQueryParameters = [
        ...changedEttersendelseQueryParameters,
        {
          key: "Dato",
          value: `${toNorwegianDatetime(
            ettersendelseQueryParameters.fraDato
          ).format("DD.MM.YYYY HH:mm")} - ${toNorwegianDatetime(
            ettersendelseQueryParameters.tilDato
          ).format("DD.MM.YYYY HH:mm")}`,
        },
      ];
    }
    if (
      initEttersendelseQueryParameters.tjenestenummer !==
      ettersendelseQueryParameters.tjenestenummer
    ) {
      changedEttersendelseQueryParameters = [
        ...changedEttersendelseQueryParameters,
        {
          key: "Tjenestenummer",
          value: ettersendelseQueryParameters.tjenestenummer,
        },
      ];
    }
    changedEttersendelseQueryParameters = [
      ...changedEttersendelseQueryParameters,
      {
        key: "Makulert",
        value: getNavnById(
          ettersendelseQueryParameters.isMakulert,
          makulertStatus
        ),
      },
    ];
    const statuser = getIleggelseQueryParameterStatusString(
      ettersendelseQueryParameters
    );
    if (statuser) {
      changedEttersendelseQueryParameters = [
        ...changedEttersendelseQueryParameters,
        {
          key: "Status",
          value: getIleggelseQueryParameterStatusString(
            ettersendelseQueryParameters
          ),
        },
      ];
    }

    return changedEttersendelseQueryParameters;
  }
);

export default getEttersendelseList;
