import React from "react";
import map from "lodash/map";
import { orderBy } from "../../utils/collectionUtils";
import copylink from "../../images/copylink.png";

const createCopyLinkImage = (link) => {
  return (
    <span
      className="pull-right"
      style={{ width: 100 }}
      onClick={() => {
        navigator.clipboard.writeText(link);
      }}
    >
      <img
        src={copylink}
        alt="clipboard"
        title="Kopi link address"
        style={{ cursor: "pointer" }}
      />
    </span>
  );
};

const sort = (list) => orderBy(list, "tittel", "asc");

export const LinkerView = ({ Linker, header }) => {
  const interne = Linker.interneLinker;
  const offentlige = Linker.offentligeLinker;
  const filressurser = Linker.filressurserLinker;
  return (
    <div>
      <div className="fake-label" style={{ fontSize: 16, fontWeight: "bold" }}>
        {header}
      </div>
      <h3>Interne linker</h3>
      {map(sort(interne), (item) => {
        return (
          <div className="16px-bold" key={item.tittel}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.tittel}
            </a>
            <br />
            <br />
          </div>
        );
      })}
      <h3>Offentlige linker</h3>
      {map(sort(offentlige), (item) => {
        return (
          <div className="16px-bold" key={item.tittel}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.tittel}
            </a>
            <br />
            <br />
          </div>
        );
      })}
      <h3>Filressurs linker</h3>
      <span className="red">
        Kopier link-adressen ved å klikke på ikonet og åpne en ny fane i
        nettleseren og lime inn adressen i nettleseren.
      </span>
      <br />
      <br />
      {map(sort(filressurser), (item) => {
        return (
          <div className="16px-bold" style={{ width: 300 }} key={item.tittel}>
            <span>
              <a
                style={{ width: 150 }}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.tittel}
              </a>
            </span>
            {createCopyLinkImage(item.link)}
            <br />
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default LinkerView;
