import { jwtDecode } from "jwt-decode";
import { getUser } from "./userUtils";

export const authorized = (allowedRoles) => {
  const user = getUser();
  if (!user?.access_token) {
    return false;
  }

  let decodedAccessToken = jwtDecode(user?.access_token);
  if (
    decodedAccessToken &&
    decodedAccessToken.roles &&
    decodedAccessToken.roles.length &&
    allowedRoles?.length
  ) {
    return decodedAccessToken.roles.some((r) => allowedRoles.includes(r));
  }
  return false;
};

export const getAccessToken = () => {
  const user = getUser();
  if (!user?.access_token) {
    return undefined;
  }
  return user.access_token;
};
