import { combineReducers } from "redux";

import aarsrapportReducer from "./aarsrapportReducer";
import antallIleggelserPerIleggelsetypeRapportReducer from "./antallIleggelserPerIleggelsetypeRapportReducer";
import makuleringerRapportReducer from "./makuleringerRapportReducer";

import createAdvancedListReducer from "../createAdvancedListReducer";

export default combineReducers({
  aarsrapport: aarsrapportReducer,
  antallIleggelserPerIleggelsetypeRapport: antallIleggelserPerIleggelsetypeRapportReducer,
  makuleringerRapport: createAdvancedListReducer(
    "RAPPORTER.MAKULERINGERRAPPORT",
    makuleringerRapportReducer,
    { cellKey: "ileggelsesdatoTil", orders: "desc" }
  ),
});
