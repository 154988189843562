import axiosPackage from "axios";
import axios from "../services/axios";
import { addFlashMessage } from "../actions/FlashMessagesAction";
import includes from "lodash/includes";

import {
  NetworkError,
  NotHandledRRessponseErrorsFromService,
} from "../constants/ErrorMessages";

function ILogger(type, text) {
  console.log(type, text);
}

export function successMessage(dispatch, entitet, performedAction) {
  if (entitet && entitet !== "") {
    entitet = entitet.toLowerCase();
    if (entitet.endsWith("er")) {
      entitet.replace("er", "");
    }
    let formatEntitet = entitet.substr(0, 1).toUpperCase() + entitet.substr(1);
    let successMsg = `${formatEntitet} ble ${performedAction}`;
    dispatch(addFlashMessage({ type: "success", text: successMsg }));
  }
}
export function errorMessage(dispatch, error, action) {
  ILogger(`${action} error message:`, error);
  const errorMessage = errorData(error);
  dispatch(addFlashMessage({ type: "error", text: errorMessage }));
  throw error;
}
export function errorData(err) {
  if (!err) return;
  if (includes(err.message, "Network Error")) {
    return NetworkError;
  } else if (err.response) {
    if (err.response.status && err.response.status === 500) {
      const data = err.response.data;
      return data
        ? takeAntallCharacters(data.errorMessage)
        : NotHandledRRessponseErrorsFromService;
    } else {
      if (err.response.data) {
        let res = err.response.data.errorMessage;
        res = takeAntallCharacters(res);
        return res;
      }
    }
  } else return err;
}

export function takeAntallCharacters(text, endNumber) {
  return text && text.length > 0 ? text.substring(0, 200) : "";
}

export function successData(response) {
  if (response.data) {
    let data = response.data;
    let status = response.status;
    if (status === 200) {
      return data.result;
    }
  }
  return response;
}

export function mergeWith(obj1, obj2) {
  if (obj1 && obj2) return Object.assign({}, obj1, obj2);
  return obj1;
}
export function handleError(dispatch, type) {
  return (error) => {
    dispatch({
      type: `GET_${type}_FAILURE`,
      error: true,
      payload: { ...error },
    });
    return Promise.reject(error);
  };
}

const SET_TOTAL = (storeKey) => "SET_TOTAL_" + storeKey.toUpperCase();
function setTotal(storeKey, value) {
  return { type: SET_TOTAL(storeKey), payload: value };
}
function xpagination(response) {
  if (response.headers && response.headers["x-pagination"])
    return JSON.parse(response.headers["x-pagination"]);
}

function setTotalPagination(response, dispatch, storeKey) {
  const xpaginationHeader = xpagination(response);
  const total =
    xpaginationHeader && xpaginationHeader.Total
      ? xpaginationHeader.Total
      : undefined;
  if (storeKey) {
    if (total > 0) dispatch(setTotal(storeKey, total));
  }
}

const actionCreator = (actionName, actionType, payload) => {
  const actionTypeSuccess = `${actionName.toUpperCase()}_${actionType.toUpperCase()}_SUCCESS`;
  return { type: actionTypeSuccess, payload };
};

export const GetAll = (url, actionType, storeKey, source) => {
  return async (dispatch) => {
    if (source) {
      return axios
        .get(url, {
          cancelToken: source.token,
        })
        .then((response) => {
          const data = successData(response);
          setTotalPagination(response, dispatch, storeKey);
          if (actionType && actionType !== "")
            dispatch(actionCreator("GET_ALL", actionType, data));
          return data;
        })
        .catch((error) => {
          if (axiosPackage.isCancel(error)) {
            return Promise.reject(error);
          } else {
            errorMessage(dispatch, error, "GetAll");
          }
        });
    } else {
      return axios
        .get(url)
        .then((response) => {
          const data = successData(response);
          setTotalPagination(response, dispatch, storeKey);
          if (actionType && actionType !== "")
            dispatch(actionCreator("GET_ALL", actionType, data));
          return data;
        })
        .catch((error) => {
          errorMessage(dispatch, error, "GetAll");
          return Promise.reject(error);
        });
    }
  };
};

export const GetAllWithParams = (
  url,
  paramObj,
  actionType,
  storeKey,
  hideErrorMessage = false
) => {
  return async (dispatch) => {
    return axios
      .get(url, { params: paramObj })
      .then((response) => {
        const data = successData(response);
        setTotalPagination(response, dispatch, storeKey);
        if (actionType && actionType !== "")
          dispatch(actionCreator("GET_ALL", actionType, data));
        return data;
      })
      .catch((error) => {
        if (hideErrorMessage) {
          return Promise.reject(error);
        }
        errorMessage(dispatch, error, "GetAllWithParams");
        return Promise.reject(error);
      });
  };
};

export const GetAllWithParamsSerializer = (
  url,
  paramObj,
  actionType,
  storeKey
) => {
  return async (dispatch) => {
    return axios
      .get(url, {
        params: paramObj,
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        const data = successData(response);
        setTotalPagination(response, dispatch, storeKey);
        if (actionType && actionType !== "")
          dispatch(actionCreator("GET_ALL", actionType, data));
        return data;
      })
      .catch((error) => {
        errorMessage(dispatch, error, "GetAllWithParams");
      });
  };
};
export const GetOne = (
  url,
  actionType,
  firstItem = false,
  metaData = { silence: true }
) => {
  return async (dispatch) => {
    return axios
      .get(url)
      .then((response) => {
        let data = successData(response);
        if (firstItem && data.length > 0) data = data[0];
        if (actionType && actionType !== "")
          dispatch(actionCreator("get_one", actionType, data));
        return data;
      })
      .catch((error) => {
        if (actionType) {
          dispatch(actionCreator("get_one", actionType, {}));
        }
        if (!!!metaData.silence) {
          errorMessage(dispatch, error, "GetOne");
        }
        return error;
      });
  };
};

const getAxiosPostCall = (url, payload, config) => {
  return config ? axios.post(url, payload, config) : axios.post(url, payload);
};
export function Post(
  url,
  payload,
  actionType,
  metaData = { getOne: null, entitet: "", mergeObj: null, notify: true },
  config = undefined
) {
  return async (dispatch) => {
    return getAxiosPostCall(url, payload, config)
      .then((response) => {
        if (metaData.notify)
          successMessage(dispatch, metaData.entitet, "lagt til.");
        const data = successData(response);
        if (actionType && actionType !== "" && data && data.id) {
          const getSingleUrl = `${url}/${data.id}`;
          axios.get(getSingleUrl).then((res) => {
            let createdData = successData(res);
            createdData = mergeWith(createdData, metaData.mergeObj);
            dispatch(actionCreator("post", actionType, createdData));
          });
        }
        return data;
      })
      .catch((error) => {
        errorMessage(dispatch, error, "Post");
        Promise.reject(error);
      });
  };
}

export function Delete(url, actionType) {
  return async (dispatch) => {
    return axios
      .delete(url)
      .then((response) => {
        const data = successData(response);
        if (actionType && actionType !== "")
          dispatch(actionCreator("delete", actionType, data));
        else return data;
      })
      .catch((error) => {
        errorMessage(dispatch, error, "Delete");
        return Promise.reject();
      });
  };
}

const getAxiosPutCall = (url, payload, config) => {
  return config ? axios.put(url, payload, config) : axios.put(url, payload);
};
export function Put(
  url,
  payload,
  actionType,
  metaData = { entitet: "", notify: true },
  config = undefined
) {
  return async (dispatch) => {
    return getAxiosPutCall(url, payload, config)
      .then((response) => {
        const data = successData(response);
        if (metaData.notify) {
          successMessage(dispatch, metaData.entitet, " oppdatert.");
        }
        if (actionType && actionType !== "" && data && data.id) {
          axios.get(url).then((res) => {
            let updateData = successData(res);
            dispatch(actionCreator("put", actionType, updateData));
          });
          return data;
        } else return data;
      })
      .catch((error) => {
        errorMessage(dispatch, error, "Put");
      });
  };
}

export function PromisAll(dispatchArray, dispatchThen) {
  return (dispatch) =>
    Promise.all(dispatchArray).then(() => {
      dispatch(dispatchThen);
    });
}

export function ResetToInitiateState(actionType, payload) {
  return (dispatch) => {
    fackePromis(payload)
      .then((i) => {
        dispatch(actionCreator("reset", actionType, i));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function fackePromis(payload) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(payload);
    }, 1);
  });
}
